import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Spin, Typography, Modal, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { App, AppVolumeMountsInner, Volume } from '../../../api/generated';
import { volumesApi } from '../../../api/apiConfig';
import { handleError } from '../../../utils';

const { error: modalError } = Modal;

interface Props {
  app: App;
  setApp: React.Dispatch<React.SetStateAction<App>>;
  newVolumeMounts: AppVolumeMountsInner;
  setNewVolumeMounts: React.Dispatch<React.SetStateAction<AppVolumeMountsInner>>;
}

const AppVolumeMounts: React.FC<Props> = ({ app, setApp, newVolumeMounts, setNewVolumeMounts }) => {
  const [volumes, setVolumes] = useState<Volume[]>([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const projectId = useParams<{ projectId?: string }>().projectId || '';

  const loadVolumes = (projectId: string) => {
    volumesApi()
      .getVolumes(projectId)
      .then((response) => {
        setVolumes(response.data || []);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadVolumes(projectId);
  }, [projectId]);

  const updateVolumeMount = (index: number, newVm: AppVolumeMountsInner) => {
    const updatedVolumeMounts = app.volumeMounts.map((vm, i) => (i === index ? newVm : vm));
    setApp(prev => ({ ...prev, volumeMounts: updatedVolumeMounts }));
  };

  const deleteVolumeMount = (index: number) => {
    const updatedVolumeMounts = app.volumeMounts.filter((_, i) => i !== index);
    setApp(prev => ({ ...prev, volumeMounts: updatedVolumeMounts }));
  };

  const addNewVolumeMount = () => {
    if (!newVolumeMounts.name || !newVolumeMounts.mountPath) {
      modalError({ title: 'Error', content: 'Both fields must be filled.' });
      return;
    }
    if (app.volumeMounts.some(vm => vm.mountPath === newVolumeMounts.mountPath)) {
      modalError({ title: 'Error', content: 'Mount path must be unique.' });
      return;
    }
    if (app.volumeMounts.some(vm => vm.name === newVolumeMounts.name)) {
      modalError({ title: 'Error', content: 'Each volume can only be mounted once.' });
      return;
    }

    setApp(prev => ({
      ...prev,
      volumeMounts: [...prev.volumeMounts, newVolumeMounts],
    }));
    resetForm();
  };

  const resetForm = () => {
    setNewVolumeMounts({ name: '', mountPath: '' });
    setShowForm(false);
  };

  if (loading) return <Spin spinning>Loading...</Spin>;

  // Filter out volumes that are already mounted
  const availableVolumes = volumes.filter(
    volume => !app.volumeMounts.find(vm => vm.name === volume.name)
  );

  const volumeOptions = availableVolumes.map(volume => ({ label: volume.name, value: volume.name }));

  const renderVolumeMountForm = (vm: AppVolumeMountsInner, index: number, isExisting: boolean) => {
    return (
      <Space key={index} style={{ display: 'flex', marginBottom: 8 }}>
        <Select
          showSearch
          placeholder="Select a volume"
          optionFilterProp="children"
          value={vm.name}
          onChange={(value) => {
            if (isExisting) {
              updateVolumeMount(index, { ...vm, name: value });
            } else {
              setNewVolumeMounts(prev => ({ ...prev, name: value }));
            }
          }}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase()) || false
          }
          options={volumeOptions}
          style={{ width: 200 }}
        />
        <Input
          addonBefore="Mount Path"
          value={vm.mountPath}
          onChange={e => isExisting ? updateVolumeMount(index, { ...vm, mountPath: e.target.value }) : setNewVolumeMounts(prev => ({ ...prev, mountPath: e.target.value }))}
          style={{ width: 300 }}
          required
        />
        {isExisting ? (
          <Button danger onClick={() => deleteVolumeMount(index)}>Delete</Button>
        ) : (
          <Button onClick={addNewVolumeMount}>Add</Button>
        )}
      </Space>
    );
  };

  return (
    <>
      <Typography.Title level={5}>Volume Mounts</Typography.Title>
      <Button

        onClick={() => setShowForm(!showForm)}
        style={{ marginTop: 16, marginBottom: 16, display: 'block' }}
      >
        Add New Volume Mount
      </Button>
      {app.volumeMounts.map((vm, index) => renderVolumeMountForm(vm, index, true))}
      {showForm && renderVolumeMountForm(newVolumeMounts, -1, false)}
    </>
  );
};

export default AppVolumeMounts;
