import React, { useState, useMemo } from 'react';
import { Button, Input, Table, Pagination } from 'antd';
import { Log, LogTypeEnum } from '../../api/generated';
import moment from 'moment';

interface LogsViewerProps {
  logs: Log[]; // Use an array of Log objects directly
  logsPerPage?: number;
}

const LogsViewer: React.FC<LogsViewerProps> = ({ logs, logsPerPage = 10 }) => {
  const [filterType, setFilterType] = useState<'all' | LogTypeEnum>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Filter logs by type if not 'all', then by search term, and finally sort by timestamp
  const filteredAndSortedLogs = useMemo(() => logs
    .filter(log => filterType === 'all' || log.type === filterType)
    .filter(log => log.message.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [logs, filterType, searchTerm]);

  // Pagination
  const paginatedLogs = useMemo(() => filteredAndSortedLogs.slice((currentPage - 1) * logsPerPage, currentPage * logsPerPage), [filteredAndSortedLogs, currentPage, logsPerPage]);

  // Table columns
  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: string) => moment(timestamp).format('LLL'), // Format the timestamp with moment
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        {['all', LogTypeEnum.Http, LogTypeEnum.Stdout].map(type => (
          <Button key={type} type={filterType === type ? 'primary' : 'default'} onClick={() => setFilterType(type === 'all' ? 'all' : type as LogTypeEnum)}>
            {type.toUpperCase()}
          </Button>
        ))}
      </div>

      <Table
        columns={columns}
        dataSource={paginatedLogs}
        rowKey={record => `${record.timestamp}-${record.type}`}
        pagination={false}
      />

      <Input
        placeholder="Search logs..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        style={{ margin: '20px 0' }}
      />

      <Pagination
        current={currentPage}
        onChange={page => setCurrentPage(page)}
        total={filteredAndSortedLogs.length}
        pageSize={logsPerPage}
        showSizeChanger={false}
        style={{ textAlign: 'center', margin: '20px 0' }}
      />
    </div>
  );
};

export default LogsViewer;
