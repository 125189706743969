import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Spin,
  Tooltip,
  Typography,
  Row,
  Col,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { Redis, RedisCredentials, RedisStatus } from "../../api/generated";
import { redisApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import MainLayout from "../../components/layout";
import CopyCredentialsModal from "../../components/redis/CopyCredentialsModal";
import ResourceDeleteModal from "../../components/resource-delete-modal/ResourceDeleteModal";

const RedisDetailsPage = () => {
  const { projectId = "", redisId = "" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [redisDetails, setRedisDetails] = useState<Redis>();
  const [redisCredentials, setredisCredentials] = useState<RedisCredentials>();
  const [redisStatus, setRedisStatus] = useState<RedisStatus>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resourceType] = useState("Redis");
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  useEffect(() => {
    loadRedisDetails(projectId, redisId);
    loadRedisCredentials(projectId, redisId);
    loadRedisStatus(projectId, redisId);
  }, [projectId, redisId]);

  const loadRedisDetails = (projectId: string, redisId: string) => {
    redisApi()
      .getRedis(projectId, redisId)
      .then((response) => {
        setRedisDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadRedisCredentials = (projectId: string, redisId: string) => {
    redisApi()
      .getRedisCredentials(projectId, redisId)
      .then((response) => {
        setredisCredentials(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const loadRedisStatus = (projectId: string, redisId: string) => {
    redisApi()
      .getRedisStatus(projectId, redisId)
      .then((response) => {
        setRedisStatus(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => setIsModalVisible(false);

  const handleDeleteRedis = () => {
    redisApi()
      .deleteRedis(projectId, redisId)
      .then(() => {
        message.success("Redis deleted successfully");
        navigate(`/projects/${projectId}/redis`);
        setIsModalVisible(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  if (loading || !redisDetails) {
    return <Spin spinning={true}>Loading...</Spin>;
  }

  return (
    <MainLayout showSider={true}>
      <h2>{redisDetails.name} - Details</h2>

      <Button
        href={`/projects/${projectId}/redises/${redisId}/edit`}
        type="primary"
      >
        Edit
      </Button>
      <ResourceDeleteModal
        handleOk={handleDeleteRedis}
        setIsModalVisible={setIsModalDeleteVisible}
        isModalVisible={isModalDeleteVisible}
        resourceType={resourceType}
        someDetails={{ name: redisDetails.name }}
      />

      <Row gutter={16}>
        <Col span={12}>
          <Card
            title="Credentials"
            extra={
              <Space>
                <Tooltip title="Copy credentials as environment variables">
                  <Button icon={<CopyOutlined />} onClick={showModal} />
                </Tooltip>
              </Space>
            }
          >
            <Form layout="vertical">
              <Form.Item>
                <Input
                  addonBefore="Hostname"
                  value={redisCredentials?.hostname}
                  readOnly
                />
              </Form.Item>
              <Form.Item>
                <Input
                  addonBefore="Port"
                  value={redisCredentials?.port}
                  readOnly
                />
              </Form.Item>
              {/* Only show password if it is not empty */}
              {redisCredentials?.password && (
                <Form.Item>
                  <Input.Password
                    addonBefore="Password"
                    value={redisCredentials?.password}
                    readOnly
                  />
                </Form.Item>
              )}
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Status" bordered={false}>
            <Typography.Paragraph>
              Available: {redisStatus?.available}
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>

      {/* Copy MySQL Credentials Modal */}
      <CopyCredentialsModal
        prefix={redisId}
        redisCredentials={redisCredentials}
        open={isModalVisible}
        onClose={handleCloseModal}
      />
    </MainLayout>
  );
};

export default RedisDetailsPage;
