import React, { useEffect } from "react";
import MainLayout from "../../components/layout";
import { projectsApi, regionsApi } from "../../api/apiConfig";
import { Project } from "../../api/generated";
import { handleError } from "../../utils";
import { Table, Button, Dropdown, Spin, MenuProps, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const DashboardPage: React.FC = () => {
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [regions, setRegions] = React.useState<string[]>([]); // State to hold the regions
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadProjects();
    getRegions();
  }, []);

  const loadProjects = () => {
    projectsApi()
      .getProjects()
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createProject = (regionName: string) => {
    projectsApi()
      .createProject(regionName)
      .then((response) => {
        setProjects([...projects, response.data]);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getRegions = () => {
    regionsApi()
      .getRegions()
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const columns = [
    {
      title: "Project ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
  ];

  const items: MenuProps["items"] = regions.map((region) => ({
    onClick: () => createProject(region),
    label: region,
    key: region,
  }));

  const menuProps = {
    items,
  };

  return (
    <MainLayout>
      <h1>Dashboard</h1>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Table
            dataSource={projects}
            columns={columns}
            key="id"
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`/projects/${record.id}`);
                },
              };
            }}
            pagination={false}
          />
          <Divider />
          <Dropdown menu={menuProps} trigger={["click"]}>
            <Button type="primary" icon={<DownOutlined />}>
              Create Project
            </Button>
          </Dropdown>
        </>
      )}
    </MainLayout>
  );
};
export default DashboardPage;
