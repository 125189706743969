/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * My API description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface App
 */
export interface App {
    /**
     * The app name
     * @type {string}
     * @memberof App
     */
    'name': string;
    /**
     * The number of replicas
     * @type {number}
     * @memberof App
     */
    'replicas': number;
    /**
     * Whether the app is public or not
     * @type {boolean}
     * @memberof App
     */
    'public': boolean;
    /**
     * The built-in domain of the app
     * @type {string}
     * @memberof App
     */
    'builtInDomain'?: string;
    /**
     * The domains of the app
     * @type {Array<string>}
     * @memberof App
     */
    'domains': Array<string>;
    /**
     * The http port
     * @type {number}
     * @memberof App
     */
    'httpPort'?: number | null;
    /**
     * The plan of the app
     * @type {string}
     * @memberof App
     */
    'plan': string;
    /**
     * 
     * @type {AppDeploymentSource}
     * @memberof App
     */
    'deploymentSource': AppDeploymentSource;
    /**
     * The command override
     * @type {string}
     * @memberof App
     */
    'commandOverride'?: string;
    /**
     * The storage volumes
     * @type {Array<AppVolumeMountsInner>}
     * @memberof App
     */
    'volumeMounts': Array<AppVolumeMountsInner>;
    /**
     * The environment variables
     * @type {Array<EnvVar>}
     * @memberof App
     */
    'envVars'?: Array<EnvVar>;
}
/**
 * The deployment source
 * @export
 * @interface AppDeploymentSource
 */
export interface AppDeploymentSource {
    /**
     * The deployment source type
     * @type {string}
     * @memberof AppDeploymentSource
     */
    'type': AppDeploymentSourceTypeEnum;
    /**
     * 
     * @type {AppDeploymentSourceDocker}
     * @memberof AppDeploymentSource
     */
    'docker'?: AppDeploymentSourceDocker;
    /**
     * 
     * @type {AppDeploymentSourceGit}
     * @memberof AppDeploymentSource
     */
    'git'?: AppDeploymentSourceGit;
}

export const AppDeploymentSourceTypeEnum = {
    Git: 'git',
    Docker: 'docker'
} as const;

export type AppDeploymentSourceTypeEnum = typeof AppDeploymentSourceTypeEnum[keyof typeof AppDeploymentSourceTypeEnum];

/**
 * The docker deployment source
 * @export
 * @interface AppDeploymentSourceDocker
 */
export interface AppDeploymentSourceDocker {
    /**
     * The docker image
     * @type {string}
     * @memberof AppDeploymentSourceDocker
     */
    'image': string;
    /**
     * The docker username
     * @type {string}
     * @memberof AppDeploymentSourceDocker
     */
    'username'?: string;
    /**
     * The docker password
     * @type {string}
     * @memberof AppDeploymentSourceDocker
     */
    'password'?: string;
    /**
     * The docker registry
     * @type {string}
     * @memberof AppDeploymentSourceDocker
     */
    'registry'?: string;
}
/**
 * The git deployment source
 * @export
 * @interface AppDeploymentSourceGit
 */
export interface AppDeploymentSourceGit {
    /**
     * The git url
     * @type {string}
     * @memberof AppDeploymentSourceGit
     */
    'url': string;
    /**
     * The git branch
     * @type {string}
     * @memberof AppDeploymentSourceGit
     */
    'branch'?: string;
    /**
     * The git token
     * @type {string}
     * @memberof AppDeploymentSourceGit
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface AppPricing
 */
export interface AppPricing {
    /**
     * The pricing plan name
     * @type {string}
     * @memberof AppPricing
     */
    'name': string;
    /**
     * The price of the app
     * @type {number}
     * @memberof AppPricing
     */
    'price': number;
    /**
     * The ram of the app
     * @type {number}
     * @memberof AppPricing
     */
    'ram': number;
    /**
     * The cores of the app
     * @type {number}
     * @memberof AppPricing
     */
    'cores': number;
    /**
     * The type of the app
     * @type {string}
     * @memberof AppPricing
     */
    'type': AppPricingTypeEnum;
}

export const AppPricingTypeEnum = {
    Shared: 'shared',
    Dedicated: 'dedicated'
} as const;

export type AppPricingTypeEnum = typeof AppPricingTypeEnum[keyof typeof AppPricingTypeEnum];

/**
 * 
 * @export
 * @interface AppStatus
 */
export interface AppStatus {
    /**
     * The build status
     * @type {string}
     * @memberof AppStatus
     */
    'buildStatus'?: AppStatusBuildStatusEnum | null;
    /**
     * Whether the app is running or not
     * @type {boolean}
     * @memberof AppStatus
     */
    'running'?: boolean;
    /**
     * The number of latest version replicas
     * @type {number}
     * @memberof AppStatus
     */
    'replicas'?: number;
}

export const AppStatusBuildStatusEnum = {
    Running: 'running',
    Failed: 'failed',
    Succeeded: 'succeeded'
} as const;

export type AppStatusBuildStatusEnum = typeof AppStatusBuildStatusEnum[keyof typeof AppStatusBuildStatusEnum];

/**
 * 
 * @export
 * @interface AppVolumeMountsInner
 */
export interface AppVolumeMountsInner {
    /**
     * The volume name
     * @type {string}
     * @memberof AppVolumeMountsInner
     */
    'name'?: string;
    /**
     * The volume mount path
     * @type {string}
     * @memberof AppVolumeMountsInner
     */
    'mountPath'?: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface EnvVar
 */
export interface EnvVar {
    /**
     * The env var name
     * @type {string}
     * @memberof EnvVar
     */
    'name': string;
    /**
     * The env var value
     * @type {string}
     * @memberof EnvVar
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * The event type
     * @type {string}
     * @memberof Event
     */
    'type': EventTypeEnum;
    /**
     * The event reason
     * @type {string}
     * @memberof Event
     */
    'reason': string;
    /**
     * The event message
     * @type {string}
     * @memberof Event
     */
    'message': string;
    /**
     * The event timestamp
     * @type {string}
     * @memberof Event
     */
    'timestamp': string;
}

export const EventTypeEnum = {
    Normal: 'Normal',
    Warning: 'Warning'
} as const;

export type EventTypeEnum = typeof EventTypeEnum[keyof typeof EventTypeEnum];

/**
 * 
 * @export
 * @interface GenericMessage
 */
export interface GenericMessage {
    /**
     * 
     * @type {string}
     * @memberof GenericMessage
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GenericMessage
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'billing_period': string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'total_amount': number;
    /**
     * 
     * @type {Array<InvoiceProject>}
     * @memberof Invoice
     */
    'projects': Array<InvoiceProject>;
}
/**
 * 
 * @export
 * @interface InvoiceOrder
 */
export interface InvoiceOrder {
    /**
     * 
     * @type {string}
     * @memberof InvoiceOrder
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceOrder
     */
    'plan': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceOrder
     */
    'type': InvoiceOrderTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceOrder
     */
    'number_of_hours'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceOrder
     */
    'billing_amount': number;
}

export const InvoiceOrderTypeEnum = {
    Hourly: 'hourly',
    Monthly: 'monthly'
} as const;

export type InvoiceOrderTypeEnum = typeof InvoiceOrderTypeEnum[keyof typeof InvoiceOrderTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceProduct
 */
export interface InvoiceProduct {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    'type': InvoiceProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    'plan': string;
    /**
     * 
     * @type {Array<InvoiceOrder>}
     * @memberof InvoiceProduct
     */
    'orders': Array<InvoiceOrder>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    'billing_amount': number;
}

export const InvoiceProductTypeEnum = {
    App: 'app',
    Volume: 'volume',
    Mysql: 'mysql',
    Redis: 'redis'
} as const;

export type InvoiceProductTypeEnum = typeof InvoiceProductTypeEnum[keyof typeof InvoiceProductTypeEnum];

/**
 * 
 * @export
 * @interface InvoiceProject
 */
export interface InvoiceProject {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProject
     */
    'name'?: string;
    /**
     * 
     * @type {Array<InvoiceProduct>}
     * @memberof InvoiceProject
     */
    'products': Array<InvoiceProduct>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProject
     */
    'billing_amount': number;
}
/**
 * The log object, used for logs
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * The log type
     * @type {string}
     * @memberof Log
     */
    'type': LogTypeEnum;
    /**
     * The log message
     * @type {string}
     * @memberof Log
     */
    'message': string;
    /**
     * The log timestamp
     * @type {string}
     * @memberof Log
     */
    'timestamp': string;
}

export const LogTypeEnum = {
    Http: 'http',
    Stdout: 'stdout'
} as const;

export type LogTypeEnum = typeof LogTypeEnum[keyof typeof LogTypeEnum];

/**
 * The metric object, used for graphs
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * The metric value
     * @type {number}
     * @memberof Metric
     */
    'value': number;
    /**
     * The metric timestamp
     * @type {string}
     * @memberof Metric
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface MetricSet
 */
export interface MetricSet {
    /**
     * The metric set name
     * @type {string}
     * @memberof MetricSet
     */
    'name': string;
    /**
     * The metrics
     * @type {Array<Metric>}
     * @memberof MetricSet
     */
    'metrics': Array<Metric>;
}
/**
 * 
 * @export
 * @interface MySQL
 */
export interface MySQL {
    /**
     * The MySQL name
     * @type {string}
     * @memberof MySQL
     */
    'name': string;
    /**
     * The plan of the MySQL instance
     * @type {string}
     * @memberof MySQL
     */
    'plan': string;
}
/**
 * 
 * @export
 * @interface MySQLCredentials
 */
export interface MySQLCredentials {
    /**
     * The MySQL hostname
     * @type {string}
     * @memberof MySQLCredentials
     */
    'hostname': string;
    /**
     * The MySQL port
     * @type {string}
     * @memberof MySQLCredentials
     */
    'port': string;
    /**
     * The MySQL username
     * @type {string}
     * @memberof MySQLCredentials
     */
    'username': string;
    /**
     * The MySQL password
     * @type {string}
     * @memberof MySQLCredentials
     */
    'password': string;
    /**
     * The MySQL database
     * @type {string}
     * @memberof MySQLCredentials
     */
    'database': string;
}
/**
 * 
 * @export
 * @interface MySQLPricing
 */
export interface MySQLPricing {
    /**
     * The pricing plan name
     * @type {string}
     * @memberof MySQLPricing
     */
    'name': string;
    /**
     * The price of the MySQL instance
     * @type {number}
     * @memberof MySQLPricing
     */
    'price': number;
    /**
     * The storage of the MySQL instance
     * @type {number}
     * @memberof MySQLPricing
     */
    'storage': number;
    /**
     * The type of the MySQL instance
     * @type {string}
     * @memberof MySQLPricing
     */
    'type': MySQLPricingTypeEnum;
    /**
     * The cores of the MySQL instance
     * @type {number}
     * @memberof MySQLPricing
     */
    'cores'?: number;
    /**
     * The ram of the MySQL instance
     * @type {number}
     * @memberof MySQLPricing
     */
    'ram'?: number;
}

export const MySQLPricingTypeEnum = {
    Dedicated: 'dedicated',
    Shared: 'shared'
} as const;

export type MySQLPricingTypeEnum = typeof MySQLPricingTypeEnum[keyof typeof MySQLPricingTypeEnum];

/**
 * 
 * @export
 * @interface MySQLStatus
 */
export interface MySQLStatus {
    /**
     * The used storage of mysql database in MB
     * @type {number}
     * @memberof MySQLStatus
     */
    'usedMB': number;
    /**
     * The role of the mysql instance
     * @type {string}
     * @memberof MySQLStatus
     */
    'role': MySQLStatusRoleEnum;
    /**
     * The migration status of the mysql instance
     * @type {string}
     * @memberof MySQLStatus
     */
    'migrationStatus'?: MySQLStatusMigrationStatusEnum | null;
    /**
     * Whether the mysql database instance is created or not
     * @type {boolean}
     * @memberof MySQLStatus
     */
    'created': boolean;
}

export const MySQLStatusRoleEnum = {
    Standard: 'standard',
    Cleaner: 'cleaner',
    Revoked: 'revoked',
    Reader: 'reader'
} as const;

export type MySQLStatusRoleEnum = typeof MySQLStatusRoleEnum[keyof typeof MySQLStatusRoleEnum];
export const MySQLStatusMigrationStatusEnum = {
    None: 'None',
    InProgress: 'InProgress',
    Succeeded: 'Succeeded',
    Failed: 'Failed'
} as const;

export type MySQLStatusMigrationStatusEnum = typeof MySQLStatusMigrationStatusEnum[keyof typeof MySQLStatusMigrationStatusEnum];

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    'type': string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    'default': boolean;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * The project friendly name set by the user
     * @type {string}
     * @memberof Project
     */
    'name'?: string;
    /**
     * The project region
     * @type {string}
     * @memberof Project
     */
    'region'?: string;
    /**
     * The project id generated by the system
     * @type {string}
     * @memberof Project
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Redis
 */
export interface Redis {
    /**
     * The Redis name
     * @type {string}
     * @memberof Redis
     */
    'name': string;
    /**
     * The plan of the Redis instance
     * @type {string}
     * @memberof Redis
     */
    'plan': string;
}
/**
 * 
 * @export
 * @interface RedisCredentials
 */
export interface RedisCredentials {
    /**
     * The Redis hostname
     * @type {string}
     * @memberof RedisCredentials
     */
    'hostname': string;
    /**
     * The Redis port
     * @type {number}
     * @memberof RedisCredentials
     */
    'port': number;
    /**
     * The Redis password
     * @type {string}
     * @memberof RedisCredentials
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RedisPricing
 */
export interface RedisPricing {
    /**
     * The pricing plan name
     * @type {string}
     * @memberof RedisPricing
     */
    'name': string;
    /**
     * The price of the Redis instance
     * @type {number}
     * @memberof RedisPricing
     */
    'price': number;
    /**
     * The storage of the Redis instance
     * @type {string}
     * @memberof RedisPricing
     */
    'storage': string;
}
/**
 * 
 * @export
 * @interface RedisStatus
 */
export interface RedisStatus {
    /**
     * Whether the redis database instance is available or not
     * @type {boolean}
     * @memberof RedisStatus
     */
    'available': boolean;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'ingressIp'?: string;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface UpdateUserPasswordRequest
 */
export interface UpdateUserPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Volume
 */
export interface Volume {
    /**
     * The volume name
     * @type {string}
     * @memberof Volume
     */
    'name': string;
    /**
     * The volume plan
     * @type {string}
     * @memberof Volume
     */
    'plan': string;
}
/**
 * 
 * @export
 * @interface VolumePricing
 */
export interface VolumePricing {
    /**
     * The pricing plan name
     * @type {string}
     * @memberof VolumePricing
     */
    'name': string;
    /**
     * The price of the volume
     * @type {number}
     * @memberof VolumePricing
     */
    'price': number;
    /**
     * The size of the volume
     * @type {number}
     * @memberof VolumePricing
     */
    'size': number;
}

/**
 * AppsApi - axios parameter creator
 * @export
 */
export const AppsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an app gets a project as a query parameter and returns the app
         * @summary Create an app
         * @param {string} projectName Name of project to create app in
         * @param {App} app App to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp: async (projectName: string, app: App, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createApp', 'projectName', projectName)
            // verify required parameter 'app' is not null or undefined
            assertParamExists('createApp', 'app', app)
            const localVarPath = `/api/projects/{projectName}/apps`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(app, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an app
         * @summary Delete an app
         * @param {string} projectName Name of project to delete app from
         * @param {string} appName Name of app to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteApp', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('deleteApp', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an app
         * @summary Get an app
         * @param {string} projectName Name of project to get app from
         * @param {string} appName Name of app to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getApp', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getApp', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get app status
         * @summary Get app status
         * @param {string} projectName Name of project to get app status from
         * @param {string} appName Name of app to get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStatus: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAppStatus', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getAppStatus', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/status`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all apps in a project from the database
         * @summary Get all apps in a project
         * @param {string} projectName Name of project to get apps from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getApps', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}/apps`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rebuild an app
         * @summary Rebuild an app
         * @param {string} projectName Name of project to rebuild app in
         * @param {string} appName Name of app to rebuild
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rebuildApp: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('rebuildApp', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('rebuildApp', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/rebuild`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restart an app
         * @summary Restart an app
         * @param {string} projectName Name of project to restart app in
         * @param {string} appName Name of app to restart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApp: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('restartApp', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('restartApp', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/restart`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an app
         * @summary Update an app
         * @param {string} projectName Name of project to update app in
         * @param {string} appName Name of app to update
         * @param {App} app App to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp: async (projectName: string, appName: string, app: App, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateApp', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('updateApp', 'appName', appName)
            // verify required parameter 'app' is not null or undefined
            assertParamExists('updateApp', 'app', app)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(app, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppsApi - functional programming interface
 * @export
 */
export const AppsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an app gets a project as a query parameter and returns the app
         * @summary Create an app
         * @param {string} projectName Name of project to create app in
         * @param {App} app App to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApp(projectName: string, app: App, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApp(projectName, app, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.createApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an app
         * @summary Delete an app
         * @param {string} projectName Name of project to delete app from
         * @param {string} appName Name of app to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApp(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApp(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.deleteApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an app
         * @summary Get an app
         * @param {string} projectName Name of project to get app from
         * @param {string} appName Name of app to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApp(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApp(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get app status
         * @summary Get app status
         * @param {string} projectName Name of project to get app status from
         * @param {string} appName Name of app to get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppStatus(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppStatus(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getAppStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all apps in a project from the database
         * @summary Get all apps in a project
         * @param {string} projectName Name of project to get apps from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApps(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<App>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApps(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.getApps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rebuild an app
         * @summary Rebuild an app
         * @param {string} projectName Name of project to rebuild app in
         * @param {string} appName Name of app to rebuild
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rebuildApp(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rebuildApp(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.rebuildApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Restart an app
         * @summary Restart an app
         * @param {string} projectName Name of project to restart app in
         * @param {string} appName Name of app to restart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartApp(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restartApp(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.restartApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an app
         * @summary Update an app
         * @param {string} projectName Name of project to update app in
         * @param {string} appName Name of app to update
         * @param {App} app App to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApp(projectName: string, appName: string, app: App, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApp(projectName, appName, app, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppsApi.updateApp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppsApi - factory interface
 * @export
 */
export const AppsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppsApiFp(configuration)
    return {
        /**
         * Create an app gets a project as a query parameter and returns the app
         * @summary Create an app
         * @param {string} projectName Name of project to create app in
         * @param {App} app App to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApp(projectName: string, app: App, options?: any): AxiosPromise<App> {
            return localVarFp.createApp(projectName, app, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an app
         * @summary Delete an app
         * @param {string} projectName Name of project to delete app from
         * @param {string} appName Name of app to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApp(projectName: string, appName: string, options?: any): AxiosPromise<App> {
            return localVarFp.deleteApp(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an app
         * @summary Get an app
         * @param {string} projectName Name of project to get app from
         * @param {string} appName Name of app to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApp(projectName: string, appName: string, options?: any): AxiosPromise<App> {
            return localVarFp.getApp(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get app status
         * @summary Get app status
         * @param {string} projectName Name of project to get app status from
         * @param {string} appName Name of app to get status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppStatus(projectName: string, appName: string, options?: any): AxiosPromise<AppStatus> {
            return localVarFp.getAppStatus(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all apps in a project from the database
         * @summary Get all apps in a project
         * @param {string} projectName Name of project to get apps from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApps(projectName: string, options?: any): AxiosPromise<Array<App>> {
            return localVarFp.getApps(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Rebuild an app
         * @summary Rebuild an app
         * @param {string} projectName Name of project to rebuild app in
         * @param {string} appName Name of app to rebuild
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rebuildApp(projectName: string, appName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.rebuildApp(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Restart an app
         * @summary Restart an app
         * @param {string} projectName Name of project to restart app in
         * @param {string} appName Name of app to restart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApp(projectName: string, appName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.restartApp(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an app
         * @summary Update an app
         * @param {string} projectName Name of project to update app in
         * @param {string} appName Name of app to update
         * @param {App} app App to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApp(projectName: string, appName: string, app: App, options?: any): AxiosPromise<App> {
            return localVarFp.updateApp(projectName, appName, app, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppsApi - object-oriented interface
 * @export
 * @class AppsApi
 * @extends {BaseAPI}
 */
export class AppsApi extends BaseAPI {
    /**
     * Create an app gets a project as a query parameter and returns the app
     * @summary Create an app
     * @param {string} projectName Name of project to create app in
     * @param {App} app App to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public createApp(projectName: string, app: App, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).createApp(projectName, app, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an app
     * @summary Delete an app
     * @param {string} projectName Name of project to delete app from
     * @param {string} appName Name of app to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public deleteApp(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).deleteApp(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an app
     * @summary Get an app
     * @param {string} projectName Name of project to get app from
     * @param {string} appName Name of app to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getApp(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getApp(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get app status
     * @summary Get app status
     * @param {string} projectName Name of project to get app status from
     * @param {string} appName Name of app to get status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getAppStatus(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getAppStatus(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all apps in a project from the database
     * @summary Get all apps in a project
     * @param {string} projectName Name of project to get apps from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public getApps(projectName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).getApps(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rebuild an app
     * @summary Rebuild an app
     * @param {string} projectName Name of project to rebuild app in
     * @param {string} appName Name of app to rebuild
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public rebuildApp(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).rebuildApp(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restart an app
     * @summary Restart an app
     * @param {string} projectName Name of project to restart app in
     * @param {string} appName Name of app to restart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public restartApp(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).restartApp(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an app
     * @summary Update an app
     * @param {string} projectName Name of project to update app in
     * @param {string} appName Name of app to update
     * @param {App} app App to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppsApi
     */
    public updateApp(projectName: string, appName: string, app: App, options?: RawAxiosRequestConfig) {
        return AppsApiFp(this.configuration).updateApp(projectName, appName, app, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainApi - axios parameter creator
 * @export
 */
export const DomainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add domain to app
         * @summary Add domain to app
         * @param {string} projectName Name of project to add domain to
         * @param {string} appName Name of app to add domain to
         * @param {string} body Domain to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDomain: async (projectName: string, appName: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('addDomain', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('addDomain', 'appName', appName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addDomain', 'body', body)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/domain`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove domain from app
         * @summary Remove domain from app
         * @param {string} projectName Name of project to remove domain from
         * @param {string} appName Name of app to remove domain from
         * @param {string} body Domain to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDomain: async (projectName: string, appName: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('removeDomain', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('removeDomain', 'appName', appName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('removeDomain', 'body', body)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/domain`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainApi - functional programming interface
 * @export
 */
export const DomainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainApiAxiosParamCreator(configuration)
    return {
        /**
         * Add domain to app
         * @summary Add domain to app
         * @param {string} projectName Name of project to add domain to
         * @param {string} appName Name of app to add domain to
         * @param {string} body Domain to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDomain(projectName: string, appName: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDomain(projectName, appName, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainApi.addDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove domain from app
         * @summary Remove domain from app
         * @param {string} projectName Name of project to remove domain from
         * @param {string} appName Name of app to remove domain from
         * @param {string} body Domain to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDomain(projectName: string, appName: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDomain(projectName, appName, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainApi.removeDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainApi - factory interface
 * @export
 */
export const DomainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainApiFp(configuration)
    return {
        /**
         * Add domain to app
         * @summary Add domain to app
         * @param {string} projectName Name of project to add domain to
         * @param {string} appName Name of app to add domain to
         * @param {string} body Domain to add
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDomain(projectName: string, appName: string, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.addDomain(projectName, appName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove domain from app
         * @summary Remove domain from app
         * @param {string} projectName Name of project to remove domain from
         * @param {string} appName Name of app to remove domain from
         * @param {string} body Domain to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDomain(projectName: string, appName: string, body: string, options?: any): AxiosPromise<string> {
            return localVarFp.removeDomain(projectName, appName, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainApi - object-oriented interface
 * @export
 * @class DomainApi
 * @extends {BaseAPI}
 */
export class DomainApi extends BaseAPI {
    /**
     * Add domain to app
     * @summary Add domain to app
     * @param {string} projectName Name of project to add domain to
     * @param {string} appName Name of app to add domain to
     * @param {string} body Domain to add
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public addDomain(projectName: string, appName: string, body: string, options?: RawAxiosRequestConfig) {
        return DomainApiFp(this.configuration).addDomain(projectName, appName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove domain from app
     * @summary Remove domain from app
     * @param {string} projectName Name of project to remove domain from
     * @param {string} appName Name of app to remove domain from
     * @param {string} body Domain to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainApi
     */
    public removeDomain(projectName: string, appName: string, body: string, options?: RawAxiosRequestConfig) {
        return DomainApiFp(this.configuration).removeDomain(projectName, appName, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnvApi - axios parameter creator
 * @export
 */
export const EnvApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get environment variables
         * @summary Get environment variables
         * @param {string} projectName Name of project to get environment variables from
         * @param {string} appName Name of app to get environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnv: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAppEnv', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getAppEnv', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/env`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set environment variables
         * @summary Set environment variables
         * @param {string} projectName Name of project to update environment variables in
         * @param {string} appName Name of app to update environment variables in
         * @param {Array<EnvVar>} envVar Environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppEnv: async (projectName: string, appName: string, envVar: Array<EnvVar>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('setAppEnv', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('setAppEnv', 'appName', appName)
            // verify required parameter 'envVar' is not null or undefined
            assertParamExists('setAppEnv', 'envVar', envVar)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/env`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(envVar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvApi - functional programming interface
 * @export
 */
export const EnvApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvApiAxiosParamCreator(configuration)
    return {
        /**
         * Get environment variables
         * @summary Get environment variables
         * @param {string} projectName Name of project to get environment variables from
         * @param {string} appName Name of app to get environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppEnv(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvVar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppEnv(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnvApi.getAppEnv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set environment variables
         * @summary Set environment variables
         * @param {string} projectName Name of project to update environment variables in
         * @param {string} appName Name of app to update environment variables in
         * @param {Array<EnvVar>} envVar Environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAppEnv(projectName: string, appName: string, envVar: Array<EnvVar>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvVar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAppEnv(projectName, appName, envVar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnvApi.setAppEnv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnvApi - factory interface
 * @export
 */
export const EnvApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvApiFp(configuration)
    return {
        /**
         * Get environment variables
         * @summary Get environment variables
         * @param {string} projectName Name of project to get environment variables from
         * @param {string} appName Name of app to get environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnv(projectName: string, appName: string, options?: any): AxiosPromise<Array<EnvVar>> {
            return localVarFp.getAppEnv(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Set environment variables
         * @summary Set environment variables
         * @param {string} projectName Name of project to update environment variables in
         * @param {string} appName Name of app to update environment variables in
         * @param {Array<EnvVar>} envVar Environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAppEnv(projectName: string, appName: string, envVar: Array<EnvVar>, options?: any): AxiosPromise<Array<EnvVar>> {
            return localVarFp.setAppEnv(projectName, appName, envVar, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvApi - object-oriented interface
 * @export
 * @class EnvApi
 * @extends {BaseAPI}
 */
export class EnvApi extends BaseAPI {
    /**
     * Get environment variables
     * @summary Get environment variables
     * @param {string} projectName Name of project to get environment variables from
     * @param {string} appName Name of app to get environment variables from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvApi
     */
    public getAppEnv(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return EnvApiFp(this.configuration).getAppEnv(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set environment variables
     * @summary Set environment variables
     * @param {string} projectName Name of project to update environment variables in
     * @param {string} appName Name of app to update environment variables in
     * @param {Array<EnvVar>} envVar Environment variables to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvApi
     */
    public setAppEnv(projectName: string, appName: string, envVar: Array<EnvVar>, options?: RawAxiosRequestConfig) {
        return EnvApiFp(this.configuration).setAppEnv(projectName, appName, envVar, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get app events
         * @summary Get app events
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEvents: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAppEvents', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getAppEvents', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/events`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Establishes a stream of events for a given app
         * @summary Get app events stream
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppEvents: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('streamAppEvents', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('streamAppEvents', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/events/stream`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get app events
         * @summary Get app events
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppEvents(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppEvents(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getAppEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Establishes a stream of events for a given app
         * @summary Get app events stream
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamAppEvents(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamAppEvents(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.streamAppEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * Get app events
         * @summary Get app events
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEvents(projectName: string, appName: string, options?: any): AxiosPromise<Array<Event>> {
            return localVarFp.getAppEvents(projectName, appName, options).then((request) => request(axios, basePath));
        },
        /**
         * Establishes a stream of events for a given app
         * @summary Get app events stream
         * @param {string} projectName Name of project to get app events from
         * @param {string} appName Name of app to get events from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppEvents(projectName: string, appName: string, options?: any): AxiosPromise<Array<Event>> {
            return localVarFp.streamAppEvents(projectName, appName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * Get app events
     * @summary Get app events
     * @param {string} projectName Name of project to get app events from
     * @param {string} appName Name of app to get events from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getAppEvents(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getAppEvents(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Establishes a stream of events for a given app
     * @summary Get app events stream
     * @param {string} projectName Name of project to get app events from
     * @param {string} appName Name of app to get events from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public streamAppEvents(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).streamAppEvents(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GlobalenvApi - axios parameter creator
 * @export
 */
export const GlobalenvApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get global environment variables
         * @summary Get global environment variables
         * @param {string} projectName Name of project to get global environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalEnv: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getGlobalEnv', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}/globalenv`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set global environment variables
         * @summary Set global environment variables
         * @param {string} projectName Name of project to update global environment variables in
         * @param {Array<EnvVar>} envVar Global environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGlobalEnv: async (projectName: string, envVar: Array<EnvVar>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('setGlobalEnv', 'projectName', projectName)
            // verify required parameter 'envVar' is not null or undefined
            assertParamExists('setGlobalEnv', 'envVar', envVar)
            const localVarPath = `/api/projects/{projectName}/globalenv`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(envVar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalenvApi - functional programming interface
 * @export
 */
export const GlobalenvApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalenvApiAxiosParamCreator(configuration)
    return {
        /**
         * Get global environment variables
         * @summary Get global environment variables
         * @param {string} projectName Name of project to get global environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalEnv(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvVar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalEnv(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalenvApi.getGlobalEnv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set global environment variables
         * @summary Set global environment variables
         * @param {string} projectName Name of project to update global environment variables in
         * @param {Array<EnvVar>} envVar Global environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setGlobalEnv(projectName: string, envVar: Array<EnvVar>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnvVar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setGlobalEnv(projectName, envVar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalenvApi.setGlobalEnv']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GlobalenvApi - factory interface
 * @export
 */
export const GlobalenvApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalenvApiFp(configuration)
    return {
        /**
         * Get global environment variables
         * @summary Get global environment variables
         * @param {string} projectName Name of project to get global environment variables from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalEnv(projectName: string, options?: any): AxiosPromise<Array<EnvVar>> {
            return localVarFp.getGlobalEnv(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Set global environment variables
         * @summary Set global environment variables
         * @param {string} projectName Name of project to update global environment variables in
         * @param {Array<EnvVar>} envVar Global environment variables to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGlobalEnv(projectName: string, envVar: Array<EnvVar>, options?: any): AxiosPromise<Array<EnvVar>> {
            return localVarFp.setGlobalEnv(projectName, envVar, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalenvApi - object-oriented interface
 * @export
 * @class GlobalenvApi
 * @extends {BaseAPI}
 */
export class GlobalenvApi extends BaseAPI {
    /**
     * Get global environment variables
     * @summary Get global environment variables
     * @param {string} projectName Name of project to get global environment variables from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalenvApi
     */
    public getGlobalEnv(projectName: string, options?: RawAxiosRequestConfig) {
        return GlobalenvApiFp(this.configuration).getGlobalEnv(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set global environment variables
     * @summary Set global environment variables
     * @param {string} projectName Name of project to update global environment variables in
     * @param {Array<EnvVar>} envVar Global environment variables to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalenvApi
     */
    public setGlobalEnv(projectName: string, envVar: Array<EnvVar>, options?: RawAxiosRequestConfig) {
        return GlobalenvApiFp(this.configuration).setGlobalEnv(projectName, envVar, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all invoices from the database
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all invoices from the database
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvoicesApi.getInvoices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * Get all invoices from the database
         * @summary Get all invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(options?: any): AxiosPromise<Array<Invoice>> {
            return localVarFp.getInvoices(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * Get all invoices from the database
     * @summary Get all invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoices(options?: RawAxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getInvoices(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get app logs
         * @summary Get app logs
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {number} maxLines Maximum number of lines to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppLogs: async (projectName: string, appName: string, maxLines: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAppLogs', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getAppLogs', 'appName', appName)
            // verify required parameter 'maxLines' is not null or undefined
            assertParamExists('getAppLogs', 'maxLines', maxLines)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/logs`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (maxLines !== undefined) {
                localVarQueryParameter['maxLines'] = maxLines;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Establishes a stream of logs for a given app
         * @summary Get app logs stream
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppLogs: async (projectName: string, appName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('streamAppLogs', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('streamAppLogs', 'appName', appName)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/logs/stream`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get app logs
         * @summary Get app logs
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {number} maxLines Maximum number of lines to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppLogs(projectName: string, appName: string, maxLines: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Log>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppLogs(projectName, appName, maxLines, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogsApi.getAppLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Establishes a stream of logs for a given app
         * @summary Get app logs stream
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async streamAppLogs(projectName: string, appName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Log>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.streamAppLogs(projectName, appName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogsApi.streamAppLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * Get app logs
         * @summary Get app logs
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {number} maxLines Maximum number of lines to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppLogs(projectName: string, appName: string, maxLines: number, options?: any): AxiosPromise<Array<Log>> {
            return localVarFp.getAppLogs(projectName, appName, maxLines, options).then((request) => request(axios, basePath));
        },
        /**
         * Establishes a stream of logs for a given app
         * @summary Get app logs stream
         * @param {string} projectName Name of project to get app logs from
         * @param {string} appName Name of app to get logs from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        streamAppLogs(projectName: string, appName: string, options?: any): AxiosPromise<Array<Log>> {
            return localVarFp.streamAppLogs(projectName, appName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * Get app logs
     * @summary Get app logs
     * @param {string} projectName Name of project to get app logs from
     * @param {string} appName Name of app to get logs from
     * @param {number} maxLines Maximum number of lines to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getAppLogs(projectName: string, appName: string, maxLines: number, options?: RawAxiosRequestConfig) {
        return LogsApiFp(this.configuration).getAppLogs(projectName, appName, maxLines, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Establishes a stream of logs for a given app
     * @summary Get app logs stream
     * @param {string} projectName Name of project to get app logs from
     * @param {string} appName Name of app to get logs from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public streamAppLogs(projectName: string, appName: string, options?: RawAxiosRequestConfig) {
        return LogsApiFp(this.configuration).streamAppLogs(projectName, appName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get app metrics
         * @summary Get app metrics
         * @param {string} projectName Name of project to get app metrics from
         * @param {string} appName Name of app to get metrics from
         * @param {GetAppMetricsTypeEnum} type Type of metric to get
         * @param {GetAppMetricsPeriodEnum} period Period of metric to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMetrics: async (projectName: string, appName: string, type: GetAppMetricsTypeEnum, period: GetAppMetricsPeriodEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getAppMetrics', 'projectName', projectName)
            // verify required parameter 'appName' is not null or undefined
            assertParamExists('getAppMetrics', 'appName', appName)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getAppMetrics', 'type', type)
            // verify required parameter 'period' is not null or undefined
            assertParamExists('getAppMetrics', 'period', period)
            const localVarPath = `/api/projects/{projectName}/apps/{appName}/metrics`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"appName"}}`, encodeURIComponent(String(appName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get app metrics
         * @summary Get app metrics
         * @param {string} projectName Name of project to get app metrics from
         * @param {string} appName Name of app to get metrics from
         * @param {GetAppMetricsTypeEnum} type Type of metric to get
         * @param {GetAppMetricsPeriodEnum} period Period of metric to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppMetrics(projectName: string, appName: string, type: GetAppMetricsTypeEnum, period: GetAppMetricsPeriodEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MetricSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppMetrics(projectName, appName, type, period, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getAppMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * Get app metrics
         * @summary Get app metrics
         * @param {string} projectName Name of project to get app metrics from
         * @param {string} appName Name of app to get metrics from
         * @param {GetAppMetricsTypeEnum} type Type of metric to get
         * @param {GetAppMetricsPeriodEnum} period Period of metric to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMetrics(projectName: string, appName: string, type: GetAppMetricsTypeEnum, period: GetAppMetricsPeriodEnum, options?: any): AxiosPromise<Array<MetricSet>> {
            return localVarFp.getAppMetrics(projectName, appName, type, period, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * Get app metrics
     * @summary Get app metrics
     * @param {string} projectName Name of project to get app metrics from
     * @param {string} appName Name of app to get metrics from
     * @param {GetAppMetricsTypeEnum} type Type of metric to get
     * @param {GetAppMetricsPeriodEnum} period Period of metric to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getAppMetrics(projectName: string, appName: string, type: GetAppMetricsTypeEnum, period: GetAppMetricsPeriodEnum, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getAppMetrics(projectName, appName, type, period, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAppMetricsTypeEnum = {
    Cpu: 'cpu',
    Memory: 'memory'
} as const;
export type GetAppMetricsTypeEnum = typeof GetAppMetricsTypeEnum[keyof typeof GetAppMetricsTypeEnum];
/**
 * @export
 */
export const GetAppMetricsPeriodEnum = {
    _1h: '1h',
    _12h: '12h',
    _48h: '48h',
    _168h: '168h'
} as const;
export type GetAppMetricsPeriodEnum = typeof GetAppMetricsPeriodEnum[keyof typeof GetAppMetricsPeriodEnum];


/**
 * MysqlApi - axios parameter creator
 * @export
 */
export const MysqlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new mysql
         * @summary Create a new mysql
         * @param {string} projectName Name of project to create mysql in
         * @param {MySQL} mySQL Mysql to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMysql: async (projectName: string, mySQL: MySQL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createMysql', 'projectName', projectName)
            // verify required parameter 'mySQL' is not null or undefined
            assertParamExists('createMysql', 'mySQL', mySQL)
            const localVarPath = `/api/projects/{projectName}/mysqls`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mySQL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a mysql
         * @summary Delete a mysql
         * @param {string} projectName Name of project to delete mysql from
         * @param {string} mysqlName Name of mysql to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMysql: async (projectName: string, mysqlName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteMysql', 'projectName', projectName)
            // verify required parameter 'mysqlName' is not null or undefined
            assertParamExists('deleteMysql', 'mysqlName', mysqlName)
            const localVarPath = `/api/projects/{projectName}/mysqls/{mysqlName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"mysqlName"}}`, encodeURIComponent(String(mysqlName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a mysql
         * @summary Get a mysql
         * @param {string} projectName Name of project to get mysql from
         * @param {string} mysqlName Name of mysql to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysql: async (projectName: string, mysqlName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getMysql', 'projectName', projectName)
            // verify required parameter 'mysqlName' is not null or undefined
            assertParamExists('getMysql', 'mysqlName', mysqlName)
            const localVarPath = `/api/projects/{projectName}/mysqls/{mysqlName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"mysqlName"}}`, encodeURIComponent(String(mysqlName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get mysql credentials
         * @summary Get mysql credentials
         * @param {string} projectName Name of project to get mysql credentials from
         * @param {string} mysqlName Name of mysql to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqlCredentials: async (projectName: string, mysqlName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getMysqlCredentials', 'projectName', projectName)
            // verify required parameter 'mysqlName' is not null or undefined
            assertParamExists('getMysqlCredentials', 'mysqlName', mysqlName)
            const localVarPath = `/api/projects/{projectName}/mysqls/{mysqlName}/credentials`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"mysqlName"}}`, encodeURIComponent(String(mysqlName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get mysql status
         * @summary Get mysql status
         * @param {string} projectName Name of project to get mysql status from
         * @param {string} mysqlName Name of mysql to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqlStatus: async (projectName: string, mysqlName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getMysqlStatus', 'projectName', projectName)
            // verify required parameter 'mysqlName' is not null or undefined
            assertParamExists('getMysqlStatus', 'mysqlName', mysqlName)
            const localVarPath = `/api/projects/{projectName}/mysqls/{mysqlName}/status`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"mysqlName"}}`, encodeURIComponent(String(mysqlName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all mysqls in a project
         * @summary Get all mysqls in a project
         * @param {string} projectName Name of project to get mysqls from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqls: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getMysqls', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}/mysqls`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a mysql
         * @summary Update a mysql
         * @param {string} projectName Name of project to update mysql in
         * @param {string} mysqlName Name of mysql to update
         * @param {MySQL} mySQL Mysql to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMysql: async (projectName: string, mysqlName: string, mySQL: MySQL, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateMysql', 'projectName', projectName)
            // verify required parameter 'mysqlName' is not null or undefined
            assertParamExists('updateMysql', 'mysqlName', mysqlName)
            // verify required parameter 'mySQL' is not null or undefined
            assertParamExists('updateMysql', 'mySQL', mySQL)
            const localVarPath = `/api/projects/{projectName}/mysqls/{mysqlName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"mysqlName"}}`, encodeURIComponent(String(mysqlName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mySQL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MysqlApi - functional programming interface
 * @export
 */
export const MysqlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MysqlApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new mysql
         * @summary Create a new mysql
         * @param {string} projectName Name of project to create mysql in
         * @param {MySQL} mySQL Mysql to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMysql(projectName: string, mySQL: MySQL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMysql(projectName, mySQL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.createMysql']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a mysql
         * @summary Delete a mysql
         * @param {string} projectName Name of project to delete mysql from
         * @param {string} mysqlName Name of mysql to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMysql(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMysql(projectName, mysqlName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.deleteMysql']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a mysql
         * @summary Get a mysql
         * @param {string} projectName Name of project to get mysql from
         * @param {string} mysqlName Name of mysql to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMysql(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MySQL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMysql(projectName, mysqlName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.getMysql']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get mysql credentials
         * @summary Get mysql credentials
         * @param {string} projectName Name of project to get mysql credentials from
         * @param {string} mysqlName Name of mysql to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMysqlCredentials(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MySQLCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMysqlCredentials(projectName, mysqlName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.getMysqlCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get mysql status
         * @summary Get mysql status
         * @param {string} projectName Name of project to get mysql status from
         * @param {string} mysqlName Name of mysql to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMysqlStatus(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MySQLStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMysqlStatus(projectName, mysqlName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.getMysqlStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all mysqls in a project
         * @summary Get all mysqls in a project
         * @param {string} projectName Name of project to get mysqls from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMysqls(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MySQL>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMysqls(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.getMysqls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a mysql
         * @summary Update a mysql
         * @param {string} projectName Name of project to update mysql in
         * @param {string} mysqlName Name of mysql to update
         * @param {MySQL} mySQL Mysql to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMysql(projectName: string, mysqlName: string, mySQL: MySQL, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMysql(projectName, mysqlName, mySQL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MysqlApi.updateMysql']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MysqlApi - factory interface
 * @export
 */
export const MysqlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MysqlApiFp(configuration)
    return {
        /**
         * Create a new mysql
         * @summary Create a new mysql
         * @param {string} projectName Name of project to create mysql in
         * @param {MySQL} mySQL Mysql to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMysql(projectName: string, mySQL: MySQL, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.createMysql(projectName, mySQL, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a mysql
         * @summary Delete a mysql
         * @param {string} projectName Name of project to delete mysql from
         * @param {string} mysqlName Name of mysql to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMysql(projectName: string, mysqlName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.deleteMysql(projectName, mysqlName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a mysql
         * @summary Get a mysql
         * @param {string} projectName Name of project to get mysql from
         * @param {string} mysqlName Name of mysql to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysql(projectName: string, mysqlName: string, options?: any): AxiosPromise<MySQL> {
            return localVarFp.getMysql(projectName, mysqlName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get mysql credentials
         * @summary Get mysql credentials
         * @param {string} projectName Name of project to get mysql credentials from
         * @param {string} mysqlName Name of mysql to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqlCredentials(projectName: string, mysqlName: string, options?: any): AxiosPromise<MySQLCredentials> {
            return localVarFp.getMysqlCredentials(projectName, mysqlName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get mysql status
         * @summary Get mysql status
         * @param {string} projectName Name of project to get mysql status from
         * @param {string} mysqlName Name of mysql to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqlStatus(projectName: string, mysqlName: string, options?: any): AxiosPromise<MySQLStatus> {
            return localVarFp.getMysqlStatus(projectName, mysqlName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all mysqls in a project
         * @summary Get all mysqls in a project
         * @param {string} projectName Name of project to get mysqls from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMysqls(projectName: string, options?: any): AxiosPromise<Array<MySQL>> {
            return localVarFp.getMysqls(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a mysql
         * @summary Update a mysql
         * @param {string} projectName Name of project to update mysql in
         * @param {string} mysqlName Name of mysql to update
         * @param {MySQL} mySQL Mysql to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMysql(projectName: string, mysqlName: string, mySQL: MySQL, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.updateMysql(projectName, mysqlName, mySQL, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MysqlApi - object-oriented interface
 * @export
 * @class MysqlApi
 * @extends {BaseAPI}
 */
export class MysqlApi extends BaseAPI {
    /**
     * Create a new mysql
     * @summary Create a new mysql
     * @param {string} projectName Name of project to create mysql in
     * @param {MySQL} mySQL Mysql to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public createMysql(projectName: string, mySQL: MySQL, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).createMysql(projectName, mySQL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a mysql
     * @summary Delete a mysql
     * @param {string} projectName Name of project to delete mysql from
     * @param {string} mysqlName Name of mysql to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public deleteMysql(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).deleteMysql(projectName, mysqlName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a mysql
     * @summary Get a mysql
     * @param {string} projectName Name of project to get mysql from
     * @param {string} mysqlName Name of mysql to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public getMysql(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).getMysql(projectName, mysqlName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get mysql credentials
     * @summary Get mysql credentials
     * @param {string} projectName Name of project to get mysql credentials from
     * @param {string} mysqlName Name of mysql to get credentials from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public getMysqlCredentials(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).getMysqlCredentials(projectName, mysqlName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get mysql status
     * @summary Get mysql status
     * @param {string} projectName Name of project to get mysql status from
     * @param {string} mysqlName Name of mysql to get status from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public getMysqlStatus(projectName: string, mysqlName: string, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).getMysqlStatus(projectName, mysqlName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all mysqls in a project
     * @summary Get all mysqls in a project
     * @param {string} projectName Name of project to get mysqls from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public getMysqls(projectName: string, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).getMysqls(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a mysql
     * @summary Update a mysql
     * @param {string} projectName Name of project to update mysql in
     * @param {string} mysqlName Name of mysql to update
     * @param {MySQL} mySQL Mysql to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MysqlApi
     */
    public updateMysql(projectName: string, mysqlName: string, mySQL: MySQL, options?: RawAxiosRequestConfig) {
        return MysqlApiFp(this.configuration).updateMysql(projectName, mysqlName, mySQL, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentMethodsApi - axios parameter creator
 * @export
 */
export const PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a payment method
         * @summary Create a payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a payment method
         * @summary Delete a payment method
         * @param {string} paymentMethodID ID of payment method to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod: async (paymentMethodID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodID' is not null or undefined
            assertParamExists('deletePaymentMethod', 'paymentMethodID', paymentMethodID)
            const localVarPath = `/api/payment-methods/{paymentMethodID}`
                .replace(`{${"paymentMethodID"}}`, encodeURIComponent(String(paymentMethodID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a payment method from the database
         * @summary Get a payment method
         * @param {string} paymentMethodID ID of payment method to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod: async (paymentMethodID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodID' is not null or undefined
            assertParamExists('getPaymentMethod', 'paymentMethodID', paymentMethodID)
            const localVarPath = `/api/payment-methods/{paymentMethodID}`
                .replace(`{${"paymentMethodID"}}`, encodeURIComponent(String(paymentMethodID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all payment methods from the database
         * @summary Get all payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set a payment method as default
         * @summary Set a payment method as default
         * @param {string} paymentMethodID ID of payment method to set as default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultPaymentMethod: async (paymentMethodID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodID' is not null or undefined
            assertParamExists('setDefaultPaymentMethod', 'paymentMethodID', paymentMethodID)
            const localVarPath = `/api/payment-methods/{paymentMethodID}/set-default`
                .replace(`{${"paymentMethodID"}}`, encodeURIComponent(String(paymentMethodID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodsApi - functional programming interface
 * @export
 */
export const PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a payment method
         * @summary Create a payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentMethod(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentMethod(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.createPaymentMethod']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a payment method
         * @summary Delete a payment method
         * @param {string} paymentMethodID ID of payment method to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePaymentMethod(paymentMethodID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.deletePaymentMethod']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a payment method from the database
         * @summary Get a payment method
         * @param {string} paymentMethodID ID of payment method to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethod(paymentMethodID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.getPaymentMethod']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all payment methods from the database
         * @summary Get all payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentMethod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.getPaymentMethods']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set a payment method as default
         * @summary Set a payment method as default
         * @param {string} paymentMethodID ID of payment method to set as default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDefaultPaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultPaymentMethod(paymentMethodID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentMethodsApi.setDefaultPaymentMethod']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentMethodsApi - factory interface
 * @export
 */
export const PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodsApiFp(configuration)
    return {
        /**
         * Create a payment method
         * @summary Create a payment method
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod(options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.createPaymentMethod(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a payment method
         * @summary Delete a payment method
         * @param {string} paymentMethodID ID of payment method to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentMethod(paymentMethodID: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.deletePaymentMethod(paymentMethodID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a payment method from the database
         * @summary Get a payment method
         * @param {string} paymentMethodID ID of payment method to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod(paymentMethodID: string, options?: any): AxiosPromise<PaymentMethod> {
            return localVarFp.getPaymentMethod(paymentMethodID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all payment methods from the database
         * @summary Get all payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(options?: any): AxiosPromise<Array<PaymentMethod>> {
            return localVarFp.getPaymentMethods(options).then((request) => request(axios, basePath));
        },
        /**
         * Set a payment method as default
         * @summary Set a payment method as default
         * @param {string} paymentMethodID ID of payment method to set as default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultPaymentMethod(paymentMethodID: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.setDefaultPaymentMethod(paymentMethodID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentMethodsApi - object-oriented interface
 * @export
 * @class PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class PaymentMethodsApi extends BaseAPI {
    /**
     * Create a payment method
     * @summary Create a payment method
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public createPaymentMethod(options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).createPaymentMethod(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a payment method
     * @summary Delete a payment method
     * @param {string} paymentMethodID ID of payment method to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public deletePaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).deletePaymentMethod(paymentMethodID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a payment method from the database
     * @summary Get a payment method
     * @param {string} paymentMethodID ID of payment method to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public getPaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).getPaymentMethod(paymentMethodID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all payment methods from the database
     * @summary Get all payment methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public getPaymentMethods(options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).getPaymentMethods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set a payment method as default
     * @summary Set a payment method as default
     * @param {string} paymentMethodID ID of payment method to set as default
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public setDefaultPaymentMethod(paymentMethodID: string, options?: RawAxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).setDefaultPaymentMethod(paymentMethodID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a project gets a region as a query parameter and returns the project
         * @summary Create a project
         * @param {string} regionName Name of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('createProject', 'regionName', regionName)
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (regionName !== undefined) {
                localVarQueryParameter['regionName'] = regionName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project
         * @summary Delete a project
         * @param {string} projectName ID of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteProject', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a project from the database
         * @summary Get a project
         * @param {string} projectName Name of project to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getProject', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all projects from the database
         * @summary Get all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a project
         * @summary Update a project
         * @param {string} projectName ID of project to update
         * @param {string} name Friendly name of project to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectName: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateProject', 'projectName', projectName)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateProject', 'name', name)
            const localVarPath = `/api/projects/{projectName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a project gets a region as a query parameter and returns the project
         * @summary Create a project
         * @param {string} regionName Name of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a project
         * @summary Delete a project
         * @param {string} projectName ID of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a project from the database
         * @summary Get a project
         * @param {string} projectName Name of project to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all projects from the database
         * @summary Get all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a project
         * @summary Update a project
         * @param {string} projectName ID of project to update
         * @param {string} name Friendly name of project to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectName: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectName, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Create a project gets a region as a query parameter and returns the project
         * @summary Create a project
         * @param {string} regionName Name of the region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(regionName: string, options?: any): AxiosPromise<Project> {
            return localVarFp.createProject(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a project
         * @summary Delete a project
         * @param {string} projectName ID of project to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.deleteProject(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a project from the database
         * @summary Get a project
         * @param {string} projectName Name of project to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectName: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all projects from the database
         * @summary Get all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(options?: any): AxiosPromise<Array<Project>> {
            return localVarFp.getProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a project
         * @summary Update a project
         * @param {string} projectName ID of project to update
         * @param {string} name Friendly name of project to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectName: string, name: string, options?: any): AxiosPromise<Project> {
            return localVarFp.updateProject(projectName, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Create a project gets a region as a query parameter and returns the project
     * @summary Create a project
     * @param {string} regionName Name of the region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(regionName: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a project
     * @summary Delete a project
     * @param {string} projectName ID of project to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(projectName: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a project from the database
     * @summary Get a project
     * @param {string} projectName Name of project to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(projectName: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all projects from the database
     * @summary Get all projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a project
     * @summary Update a project
     * @param {string} projectName ID of project to update
     * @param {string} name Friendly name of project to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(projectName: string, name: string, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(projectName, name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RedisApi - axios parameter creator
 * @export
 */
export const RedisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a redis
         * @summary Create a redis
         * @param {string} projectName Name of project to create redis in
         * @param {Redis} redis Redis to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedis: async (projectName: string, redis: Redis, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createRedis', 'projectName', projectName)
            // verify required parameter 'redis' is not null or undefined
            assertParamExists('createRedis', 'redis', redis)
            const localVarPath = `/api/projects/{projectName}/redises`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a redis
         * @summary Delete a redis
         * @param {string} projectName Name of project to delete redis from
         * @param {string} redisName Name of redis to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRedis: async (projectName: string, redisName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteRedis', 'projectName', projectName)
            // verify required parameter 'redisName' is not null or undefined
            assertParamExists('deleteRedis', 'redisName', redisName)
            const localVarPath = `/api/projects/{projectName}/redises/{redisName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"redisName"}}`, encodeURIComponent(String(redisName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get redis
         * @summary Get redis
         * @param {string} projectName Name of project to get redis from
         * @param {string} redisName Name of redis to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedis: async (projectName: string, redisName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getRedis', 'projectName', projectName)
            // verify required parameter 'redisName' is not null or undefined
            assertParamExists('getRedis', 'redisName', redisName)
            const localVarPath = `/api/projects/{projectName}/redises/{redisName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"redisName"}}`, encodeURIComponent(String(redisName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get redis credentials
         * @summary Get redis credentials
         * @param {string} projectName Name of project to get redis credentials from
         * @param {string} redisName Name of redis to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedisCredentials: async (projectName: string, redisName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getRedisCredentials', 'projectName', projectName)
            // verify required parameter 'redisName' is not null or undefined
            assertParamExists('getRedisCredentials', 'redisName', redisName)
            const localVarPath = `/api/projects/{projectName}/redises/{redisName}/credentials`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"redisName"}}`, encodeURIComponent(String(redisName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get redis status
         * @summary Get redis status
         * @param {string} projectName Name of project to get redis status from
         * @param {string} redisName Name of redis to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedisStatus: async (projectName: string, redisName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getRedisStatus', 'projectName', projectName)
            // verify required parameter 'redisName' is not null or undefined
            assertParamExists('getRedisStatus', 'redisName', redisName)
            const localVarPath = `/api/projects/{projectName}/redises/{redisName}/status`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"redisName"}}`, encodeURIComponent(String(redisName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get redises
         * @summary Get redises
         * @param {string} projectName Name of project to get redises from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedises: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getRedises', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}/redises`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a redis
         * @summary Update a redis
         * @param {string} projectName Name of project to update redis in
         * @param {string} redisName Name of redis to update
         * @param {Redis} redis Redis to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedis: async (projectName: string, redisName: string, redis: Redis, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateRedis', 'projectName', projectName)
            // verify required parameter 'redisName' is not null or undefined
            assertParamExists('updateRedis', 'redisName', redisName)
            // verify required parameter 'redis' is not null or undefined
            assertParamExists('updateRedis', 'redis', redis)
            const localVarPath = `/api/projects/{projectName}/redises/{redisName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"redisName"}}`, encodeURIComponent(String(redisName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RedisApi - functional programming interface
 * @export
 */
export const RedisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RedisApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a redis
         * @summary Create a redis
         * @param {string} projectName Name of project to create redis in
         * @param {Redis} redis Redis to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRedis(projectName: string, redis: Redis, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRedis(projectName, redis, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.createRedis']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a redis
         * @summary Delete a redis
         * @param {string} projectName Name of project to delete redis from
         * @param {string} redisName Name of redis to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRedis(projectName: string, redisName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRedis(projectName, redisName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.deleteRedis']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get redis
         * @summary Get redis
         * @param {string} projectName Name of project to get redis from
         * @param {string} redisName Name of redis to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedis(projectName: string, redisName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Redis>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedis(projectName, redisName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.getRedis']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get redis credentials
         * @summary Get redis credentials
         * @param {string} projectName Name of project to get redis credentials from
         * @param {string} redisName Name of redis to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedisCredentials(projectName: string, redisName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedisCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedisCredentials(projectName, redisName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.getRedisCredentials']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get redis status
         * @summary Get redis status
         * @param {string} projectName Name of project to get redis status from
         * @param {string} redisName Name of redis to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedisStatus(projectName: string, redisName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedisStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedisStatus(projectName, redisName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.getRedisStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get redises
         * @summary Get redises
         * @param {string} projectName Name of project to get redises from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedises(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Redis>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedises(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.getRedises']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a redis
         * @summary Update a redis
         * @param {string} projectName Name of project to update redis in
         * @param {string} redisName Name of redis to update
         * @param {Redis} redis Redis to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRedis(projectName: string, redisName: string, redis: Redis, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRedis(projectName, redisName, redis, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RedisApi.updateRedis']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RedisApi - factory interface
 * @export
 */
export const RedisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RedisApiFp(configuration)
    return {
        /**
         * Create a redis
         * @summary Create a redis
         * @param {string} projectName Name of project to create redis in
         * @param {Redis} redis Redis to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRedis(projectName: string, redis: Redis, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.createRedis(projectName, redis, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a redis
         * @summary Delete a redis
         * @param {string} projectName Name of project to delete redis from
         * @param {string} redisName Name of redis to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRedis(projectName: string, redisName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.deleteRedis(projectName, redisName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get redis
         * @summary Get redis
         * @param {string} projectName Name of project to get redis from
         * @param {string} redisName Name of redis to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedis(projectName: string, redisName: string, options?: any): AxiosPromise<Redis> {
            return localVarFp.getRedis(projectName, redisName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get redis credentials
         * @summary Get redis credentials
         * @param {string} projectName Name of project to get redis credentials from
         * @param {string} redisName Name of redis to get credentials from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedisCredentials(projectName: string, redisName: string, options?: any): AxiosPromise<RedisCredentials> {
            return localVarFp.getRedisCredentials(projectName, redisName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get redis status
         * @summary Get redis status
         * @param {string} projectName Name of project to get redis status from
         * @param {string} redisName Name of redis to get status from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedisStatus(projectName: string, redisName: string, options?: any): AxiosPromise<RedisStatus> {
            return localVarFp.getRedisStatus(projectName, redisName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get redises
         * @summary Get redises
         * @param {string} projectName Name of project to get redises from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedises(projectName: string, options?: any): AxiosPromise<Array<Redis>> {
            return localVarFp.getRedises(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a redis
         * @summary Update a redis
         * @param {string} projectName Name of project to update redis in
         * @param {string} redisName Name of redis to update
         * @param {Redis} redis Redis to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRedis(projectName: string, redisName: string, redis: Redis, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.updateRedis(projectName, redisName, redis, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RedisApi - object-oriented interface
 * @export
 * @class RedisApi
 * @extends {BaseAPI}
 */
export class RedisApi extends BaseAPI {
    /**
     * Create a redis
     * @summary Create a redis
     * @param {string} projectName Name of project to create redis in
     * @param {Redis} redis Redis to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public createRedis(projectName: string, redis: Redis, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).createRedis(projectName, redis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a redis
     * @summary Delete a redis
     * @param {string} projectName Name of project to delete redis from
     * @param {string} redisName Name of redis to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public deleteRedis(projectName: string, redisName: string, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).deleteRedis(projectName, redisName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get redis
     * @summary Get redis
     * @param {string} projectName Name of project to get redis from
     * @param {string} redisName Name of redis to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public getRedis(projectName: string, redisName: string, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).getRedis(projectName, redisName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get redis credentials
     * @summary Get redis credentials
     * @param {string} projectName Name of project to get redis credentials from
     * @param {string} redisName Name of redis to get credentials from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public getRedisCredentials(projectName: string, redisName: string, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).getRedisCredentials(projectName, redisName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get redis status
     * @summary Get redis status
     * @param {string} projectName Name of project to get redis status from
     * @param {string} redisName Name of redis to get status from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public getRedisStatus(projectName: string, redisName: string, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).getRedisStatus(projectName, redisName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get redises
     * @summary Get redises
     * @param {string} projectName Name of project to get redises from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public getRedises(projectName: string, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).getRedises(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a redis
     * @summary Update a redis
     * @param {string} projectName Name of project to update redis in
     * @param {string} redisName Name of redis to update
     * @param {Redis} redis Redis to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RedisApi
     */
    public updateRedis(projectName: string, redisName: string, redis: Redis, options?: RawAxiosRequestConfig) {
        return RedisApiFp(this.configuration).updateRedis(projectName, redisName, redis, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegionsApi - axios parameter creator
 * @export
 */
export const RegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a region from the database
         * @summary Get a region
         * @param {string} regionName Name of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('getRegion', 'regionName', regionName)
            const localVarPath = `/api/regions/{regionName}`
                .replace(`{${"regionName"}}`, encodeURIComponent(String(regionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get region pricing
         * @summary Get region app pricing
         * @param {string} regionName Name of region to get pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionAppPricing: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('getRegionAppPricing', 'regionName', regionName)
            const localVarPath = `/api/regions/{regionName}/pricing/apps`
                .replace(`{${"regionName"}}`, encodeURIComponent(String(regionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get region MySQL pricing
         * @summary Get region MySQL pricing
         * @param {string} regionName Name of region to get MySQL pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionMySQLPricing: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('getRegionMySQLPricing', 'regionName', regionName)
            const localVarPath = `/api/regions/{regionName}/pricing/mysql`
                .replace(`{${"regionName"}}`, encodeURIComponent(String(regionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get region Redis pricing
         * @summary Get region Redis pricing
         * @param {string} regionName Name of region to get Redis pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionRedisPricing: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('getRegionRedisPricing', 'regionName', regionName)
            const localVarPath = `/api/regions/{regionName}/pricing/redis`
                .replace(`{${"regionName"}}`, encodeURIComponent(String(regionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get region volume pricing
         * @summary Get region volume pricing
         * @param {string} regionName Name of region to get volume pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionVolumePricing: async (regionName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('getRegionVolumePricing', 'regionName', regionName)
            const localVarPath = `/api/regions/{regionName}/pricing/volume`
                .replace(`{${"regionName"}}`, encodeURIComponent(String(regionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all regions from the database
         * @summary Get all regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionsApi - functional programming interface
 * @export
 */
export const RegionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a region from the database
         * @summary Get a region
         * @param {string} regionName Name of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegion(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Region>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegion(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get region pricing
         * @summary Get region app pricing
         * @param {string} regionName Name of region to get pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionAppPricing(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppPricing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionAppPricing(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegionAppPricing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get region MySQL pricing
         * @summary Get region MySQL pricing
         * @param {string} regionName Name of region to get MySQL pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionMySQLPricing(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MySQLPricing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionMySQLPricing(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegionMySQLPricing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get region Redis pricing
         * @summary Get region Redis pricing
         * @param {string} regionName Name of region to get Redis pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionRedisPricing(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RedisPricing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionRedisPricing(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegionRedisPricing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get region volume pricing
         * @summary Get region volume pricing
         * @param {string} regionName Name of region to get volume pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionVolumePricing(regionName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VolumePricing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionVolumePricing(regionName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegionVolumePricing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all regions from the database
         * @summary Get all regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegionsApi.getRegions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegionsApi - factory interface
 * @export
 */
export const RegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionsApiFp(configuration)
    return {
        /**
         * Get a region from the database
         * @summary Get a region
         * @param {string} regionName Name of the region to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegion(regionName: string, options?: any): AxiosPromise<Region> {
            return localVarFp.getRegion(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get region pricing
         * @summary Get region app pricing
         * @param {string} regionName Name of region to get pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionAppPricing(regionName: string, options?: any): AxiosPromise<Array<AppPricing>> {
            return localVarFp.getRegionAppPricing(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get region MySQL pricing
         * @summary Get region MySQL pricing
         * @param {string} regionName Name of region to get MySQL pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionMySQLPricing(regionName: string, options?: any): AxiosPromise<Array<MySQLPricing>> {
            return localVarFp.getRegionMySQLPricing(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get region Redis pricing
         * @summary Get region Redis pricing
         * @param {string} regionName Name of region to get Redis pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionRedisPricing(regionName: string, options?: any): AxiosPromise<Array<RedisPricing>> {
            return localVarFp.getRegionRedisPricing(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get region volume pricing
         * @summary Get region volume pricing
         * @param {string} regionName Name of region to get volume pricing from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionVolumePricing(regionName: string, options?: any): AxiosPromise<Array<VolumePricing>> {
            return localVarFp.getRegionVolumePricing(regionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all regions from the database
         * @summary Get all regions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegions(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRegions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegionsApi - object-oriented interface
 * @export
 * @class RegionsApi
 * @extends {BaseAPI}
 */
export class RegionsApi extends BaseAPI {
    /**
     * Get a region from the database
     * @summary Get a region
     * @param {string} regionName Name of the region to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegion(regionName: string, options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegion(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get region pricing
     * @summary Get region app pricing
     * @param {string} regionName Name of region to get pricing from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegionAppPricing(regionName: string, options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegionAppPricing(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get region MySQL pricing
     * @summary Get region MySQL pricing
     * @param {string} regionName Name of region to get MySQL pricing from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegionMySQLPricing(regionName: string, options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegionMySQLPricing(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get region Redis pricing
     * @summary Get region Redis pricing
     * @param {string} regionName Name of region to get Redis pricing from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegionRedisPricing(regionName: string, options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegionRedisPricing(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get region volume pricing
     * @summary Get region volume pricing
     * @param {string} regionName Name of region to get volume pricing from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegionVolumePricing(regionName: string, options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegionVolumePricing(regionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all regions from the database
     * @summary Get all regions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionsApi
     */
    public getRegions(options?: RawAxiosRequestConfig) {
        return RegionsApiFp(this.configuration).getRegions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Confirm a user
         * @summary Confirm a user
         * @param {string} token Token to confirm user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmUser', 'token', token)
            const localVarPath = `/api/users/confirm/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {CreateUserRequest} createUserRequest User object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserRequest: CreateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user
         * @summary Get a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login a user
         * @summary Login a user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (createUserRequest: CreateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('loginUser', 'createUserRequest', createUserRequest)
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout a user
         * @summary Logout a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user password
         * @summary Update a user password
         * @param {UpdateUserPasswordRequest} updateUserPasswordRequest User object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (updateUserPasswordRequest: UpdateUserPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserPasswordRequest' is not null or undefined
            assertParamExists('updateUserPassword', 'updateUserPasswordRequest', updateUserPasswordRequest)
            const localVarPath = `/api/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Confirm a user
         * @summary Confirm a user
         * @param {string} token Token to confirm user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUser(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUser(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.confirmUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {CreateUserRequest} createUserRequest User object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a user
         * @summary Get a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Login a user
         * @summary Login a user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(createUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.loginUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Logout a user
         * @summary Logout a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.logoutUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a user password
         * @summary Update a user password
         * @param {UpdateUserPasswordRequest} updateUserPasswordRequest User object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(updateUserPasswordRequest: UpdateUserPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword(updateUserPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.updateUserPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Confirm a user
         * @summary Confirm a user
         * @param {string} token Token to confirm user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUser(token: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.confirmUser(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new user
         * @summary Create a new user
         * @param {CreateUserRequest} createUserRequest User object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.createUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user
         * @summary Get a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Login a user
         * @summary Login a user
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.loginUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout a user
         * @summary Logout a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.logoutUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user password
         * @summary Update a user password
         * @param {UpdateUserPasswordRequest} updateUserPasswordRequest User object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(updateUserPasswordRequest: UpdateUserPasswordRequest, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.updateUserPassword(updateUserPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Confirm a user
     * @summary Confirm a user
     * @param {string} token Token to confirm user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public confirmUser(token: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).confirmUser(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new user
     * @summary Create a new user
     * @param {CreateUserRequest} createUserRequest User object that needs to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user
     * @summary Get a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login a user
     * @summary Login a user
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loginUser(createUserRequest: CreateUserRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).loginUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout a user
     * @summary Logout a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public logoutUser(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).logoutUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user password
     * @summary Update a user password
     * @param {UpdateUserPasswordRequest} updateUserPasswordRequest User object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserPassword(updateUserPasswordRequest: UpdateUserPasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserPassword(updateUserPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VolumeApi - axios parameter creator
 * @export
 */
export const VolumeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new storage volume
         * @summary Create a new storage volume
         * @param {string} projectName Name of project to create volume in
         * @param {Volume} volume Volume volume to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume: async (projectName: string, volume: Volume, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('createVolume', 'projectName', projectName)
            // verify required parameter 'volume' is not null or undefined
            assertParamExists('createVolume', 'volume', volume)
            const localVarPath = `/api/projects/{projectName}/volumes`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(volume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a storage volume
         * @summary Delete a storage volume
         * @param {string} projectName Name of project to delete volume from
         * @param {string} volumeName Name of volume to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume: async (projectName: string, volumeName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('deleteVolume', 'projectName', projectName)
            // verify required parameter 'volumeName' is not null or undefined
            assertParamExists('deleteVolume', 'volumeName', volumeName)
            const localVarPath = `/api/projects/{projectName}/volume/{volumeName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"volumeName"}}`, encodeURIComponent(String(volumeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a storage volume
         * @summary Get a storage volume
         * @param {string} projectName Name of project to get volume from
         * @param {string} volumeName Name of volume to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolume: async (projectName: string, volumeName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getVolume', 'projectName', projectName)
            // verify required parameter 'volumeName' is not null or undefined
            assertParamExists('getVolume', 'volumeName', volumeName)
            const localVarPath = `/api/projects/{projectName}/volume/{volumeName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"volumeName"}}`, encodeURIComponent(String(volumeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all storage volumes for a project
         * @summary Get all storage volumes for a project
         * @param {string} projectName Name of project to get volume from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumes: async (projectName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('getVolumes', 'projectName', projectName)
            const localVarPath = `/api/projects/{projectName}/volumes`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a storage volume
         * @summary Update a storage volume
         * @param {string} projectName Name of project to update volume in
         * @param {string} volumeName Name of volume to update
         * @param {Volume} volume Volume volume to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume: async (projectName: string, volumeName: string, volume: Volume, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectName' is not null or undefined
            assertParamExists('updateVolume', 'projectName', projectName)
            // verify required parameter 'volumeName' is not null or undefined
            assertParamExists('updateVolume', 'volumeName', volumeName)
            // verify required parameter 'volume' is not null or undefined
            assertParamExists('updateVolume', 'volume', volume)
            const localVarPath = `/api/projects/{projectName}/volume/{volumeName}`
                .replace(`{${"projectName"}}`, encodeURIComponent(String(projectName)))
                .replace(`{${"volumeName"}}`, encodeURIComponent(String(volumeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(volume, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VolumeApi - functional programming interface
 * @export
 */
export const VolumeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VolumeApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new storage volume
         * @summary Create a new storage volume
         * @param {string} projectName Name of project to create volume in
         * @param {Volume} volume Volume volume to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVolume(projectName: string, volume: Volume, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVolume(projectName, volume, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VolumeApi.createVolume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a storage volume
         * @summary Delete a storage volume
         * @param {string} projectName Name of project to delete volume from
         * @param {string} volumeName Name of volume to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVolume(projectName: string, volumeName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVolume(projectName, volumeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VolumeApi.deleteVolume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a storage volume
         * @summary Get a storage volume
         * @param {string} projectName Name of project to get volume from
         * @param {string} volumeName Name of volume to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolume(projectName: string, volumeName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Volume>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolume(projectName, volumeName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VolumeApi.getVolume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all storage volumes for a project
         * @summary Get all storage volumes for a project
         * @param {string} projectName Name of project to get volume from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumes(projectName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Volume>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumes(projectName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VolumeApi.getVolumes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a storage volume
         * @summary Update a storage volume
         * @param {string} projectName Name of project to update volume in
         * @param {string} volumeName Name of volume to update
         * @param {Volume} volume Volume volume to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVolume(projectName: string, volumeName: string, volume: Volume, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVolume(projectName, volumeName, volume, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VolumeApi.updateVolume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VolumeApi - factory interface
 * @export
 */
export const VolumeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VolumeApiFp(configuration)
    return {
        /**
         * Create a new storage volume
         * @summary Create a new storage volume
         * @param {string} projectName Name of project to create volume in
         * @param {Volume} volume Volume volume to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVolume(projectName: string, volume: Volume, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.createVolume(projectName, volume, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a storage volume
         * @summary Delete a storage volume
         * @param {string} projectName Name of project to delete volume from
         * @param {string} volumeName Name of volume to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVolume(projectName: string, volumeName: string, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.deleteVolume(projectName, volumeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a storage volume
         * @summary Get a storage volume
         * @param {string} projectName Name of project to get volume from
         * @param {string} volumeName Name of volume to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolume(projectName: string, volumeName: string, options?: any): AxiosPromise<Volume> {
            return localVarFp.getVolume(projectName, volumeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all storage volumes for a project
         * @summary Get all storage volumes for a project
         * @param {string} projectName Name of project to get volume from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumes(projectName: string, options?: any): AxiosPromise<Array<Volume>> {
            return localVarFp.getVolumes(projectName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a storage volume
         * @summary Update a storage volume
         * @param {string} projectName Name of project to update volume in
         * @param {string} volumeName Name of volume to update
         * @param {Volume} volume Volume volume to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVolume(projectName: string, volumeName: string, volume: Volume, options?: any): AxiosPromise<GenericMessage> {
            return localVarFp.updateVolume(projectName, volumeName, volume, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VolumeApi - object-oriented interface
 * @export
 * @class VolumeApi
 * @extends {BaseAPI}
 */
export class VolumeApi extends BaseAPI {
    /**
     * Create a new storage volume
     * @summary Create a new storage volume
     * @param {string} projectName Name of project to create volume in
     * @param {Volume} volume Volume volume to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumeApi
     */
    public createVolume(projectName: string, volume: Volume, options?: RawAxiosRequestConfig) {
        return VolumeApiFp(this.configuration).createVolume(projectName, volume, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a storage volume
     * @summary Delete a storage volume
     * @param {string} projectName Name of project to delete volume from
     * @param {string} volumeName Name of volume to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumeApi
     */
    public deleteVolume(projectName: string, volumeName: string, options?: RawAxiosRequestConfig) {
        return VolumeApiFp(this.configuration).deleteVolume(projectName, volumeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a storage volume
     * @summary Get a storage volume
     * @param {string} projectName Name of project to get volume from
     * @param {string} volumeName Name of volume to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumeApi
     */
    public getVolume(projectName: string, volumeName: string, options?: RawAxiosRequestConfig) {
        return VolumeApiFp(this.configuration).getVolume(projectName, volumeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all storage volumes for a project
     * @summary Get all storage volumes for a project
     * @param {string} projectName Name of project to get volume from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumeApi
     */
    public getVolumes(projectName: string, options?: RawAxiosRequestConfig) {
        return VolumeApiFp(this.configuration).getVolumes(projectName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a storage volume
     * @summary Update a storage volume
     * @param {string} projectName Name of project to update volume in
     * @param {string} volumeName Name of volume to update
     * @param {Volume} volume Volume volume to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumeApi
     */
    public updateVolume(projectName: string, volumeName: string, volume: Volume, options?: RawAxiosRequestConfig) {
        return VolumeApiFp(this.configuration).updateVolume(projectName, volumeName, volume, options).then((request) => request(this.axios, this.basePath));
    }
}



