import React, { useEffect, useState } from 'react';
import { Collapse, Table, Spin } from 'antd';
import MainLayout from '../../components/layout';
import { invoicesApi } from '../../api/apiConfig';
import { Invoice, InvoiceProject } from '../../api/generated';
import { handleError } from '../../utils';

const { Panel } = Collapse;

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    invoicesApi().getInvoices()
      .then(response => {
        setInvoices(response.data);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const projectColumns = [
    {
      title: 'Project ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Billing Amount',
      dataIndex: 'billing_amount',
      key: 'billing_amount',
      render: (text: number) => `$${text.toFixed(2)}`,
    },
  ];

  const productColumns = [
    {
      title: 'Product ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Billing Amount',
      dataIndex: 'billing_amount',
      key: 'billing_amount',
      render: (text: number) => `$${text.toFixed(2)}`,
    },
  ];

  const orderColumns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      key: 'plan',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Hours',
      dataIndex: 'number_of_hours',
      key: 'number_of_hours',
    },
    {
      title: 'Billing Amount',
      dataIndex: 'billing_amount',
      key: 'billing_amount',
      render: (text: number) => `$${text.toFixed(4)}`,
    },
  ];

  const renderProjectDetails = (projects: InvoiceProject[]) => (
    <Table columns={projectColumns} dataSource={projects} pagination={false} rowKey="id"
      expandable={{
        expandedRowRender: project => (
          <Table columns={productColumns} dataSource={project.products} pagination={false} rowKey="id"
            expandable={{
              expandedRowRender: product => (
                <Table columns={orderColumns} dataSource={product.orders} pagination={false} rowKey="id" />
              ),
            }}
          />
        ),
      }}
    />
  );

  return (
    <MainLayout>
      <h2>Invoices</h2>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Collapse>
          {invoices.map(invoice => (
            <Panel header={`Invoice ID: ${invoice.id} - Billing Period: ${invoice.billing_period}`} key={invoice.id}>
              <p><b>Total Amount:</b> ${invoice.total_amount.toFixed(2)}</p>
              {renderProjectDetails(invoice.projects)}
            </Panel>
          ))}
        </Collapse>
      )}
    </MainLayout>
  );
};

export default Invoices;
