import { notification } from 'antd';
import { AppDeploymentSource, AppDeploymentSourceTypeEnum } from '../api/generated';

export const connectionError = "Cannot connect to the server. Please check your network connection or try again later.";

export const handleError = (error: any) => {
  let errorMessage = connectionError;
  // Log the error for debugging purposes
  console.error(error);
  console.error(error.response?.data);

  // Customizing the error message based on the response
  if (error.response) {
    if (error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error;
    } else if (error.response.data && typeof error.response.data === 'string') {
      errorMessage = error.response.data;
    }
  }

  // Using Ant Design's notification system to display the error
  notification.error({
    message: 'Error',
    description: errorMessage,
    placement: 'top',
    duration: 4.5, // in seconds
  });
};

export function formatDeploymentMessage(source: AppDeploymentSource): string {
  switch (source.type) {
    case AppDeploymentSourceTypeEnum.Docker:
      return `Deployed with <b>${source.type}</b>${source.docker ? ` using image: <b>${source.docker.image}</b>` : ""
        }`;
    case AppDeploymentSourceTypeEnum.Git:
      return `Deployed with <b>${source.type}</b>${source.git ? ` from <b>${source.git.url}</b>` : ""
        }`;
    default:
      return `Deployed with <b>${source.type}</b>`;
  }
}
