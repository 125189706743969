import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { mysqlApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { MySQL, MySQLPricing } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const CreateMySQL: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId = "" } = useParams();
  const [mySQLPricings, setMySQLPricings] = useState<MySQLPricing[]>([]);
  const [mySQL, setmySQL] = useState<MySQL>({
    name: "",
    plan: "",
  });

  useEffect(() => {
    projectsApi().getProject(projectId).then((response) => {
      if (response.data) {
        // check that the region is set
        const region = response.data.region || '';

        // get the app pricing
        regionsApi().getRegionMySQLPricing(region).then((response) => {
          // sort the pricing by price
          response.data.sort((a, b) => a.price - b.price);

          setMySQLPricings(response.data);
        }).catch((error) => {
          handleError(error);
        });
      }
    }).catch((error) => {
      handleError(error);
    });

  }, [projectId]);


  const onFinish = (values: MySQL) => {
    setLoading(true);
    mysqlApi()
      .createMysql(projectId, values)
      .then(() => {
        message.success("MySQL created successfully");
        navigate(`/projects/${projectId}/mysqls`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainLayout showSider={true}>
      <h1>Create New MySQL</h1>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the mySQL's name!" },
            ]}
          >
            <Input
              value={mySQL.name}
              onChange={(e) =>
                setmySQL((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Plan:" name="plan" rules={[{ required: true }]}>
            <Select>
              {mySQLPricings.map((plan) => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} - ${plan.type} ${plan.type !== 'shared' ? `(${plan.ram}GB RAM, ${plan.cores} Cores)` : ''
                    }`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create MySQL
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default CreateMySQL;
