import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { appsApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import { App, AppVolumeMountsInner, AppPricing } from "../../api/generated";
import { AppDeploymentSourceTypeEnum } from "../../api/generated";
import AppDeploymentConfig from "../../components/apps/forms/AppDeploymentConfig";
import AppReplicas from "../../components/apps/forms/AppReplicas";
import AppPlan from "../../components/apps/forms/AppPlan";
import AppHttpService from "../../components/apps/forms/AppHttpService";
import AppVolumeMounts from "../../components/apps/forms/AppVolumeMounts";

const CreateApp: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId = "" } = useParams();
  const [hasHttpService, setHasHttpService] = useState(false);
  const [isAppPublic, setIsAppPublic] = useState(false);

  const [app, setApp] = useState<App>({
    name: "",
    deploymentSource: {
      type: "docker" as AppDeploymentSourceTypeEnum,
      docker: {
        image: "",
        username: "",
        password: "",
      },
      git: {
        url: "",
        branch: "",
        token: "",
      },
    },
    replicas: 0,
    public: false,
    plan: "shared",
    volumeMounts: [] as AppVolumeMountsInner[],
    domains: [] as string[],
  });

  const [newVolumeMounts, setNewVolumeMounts] = useState<AppVolumeMountsInner>(
    {}
  );

  const [appPricings, setAppPricings] = useState<AppPricing[]>([]);

  useEffect(() => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        if (response.data) {
          // check that the region is set
          const region = response.data.region || "";

          // get the app pricing
          regionsApi()
            .getRegionAppPricing(region)
            .then((response) => {
              // sort the app pricing by price
              response.data.sort((a, b) => a.price - b.price);

              setAppPricings(response.data);
            })
            .catch((error) => {
              handleError(error);
            });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [projectId]);

  const onFinish = (values: App) => {
    const newApp = {
      ...values,
      volumeMounts: app.volumeMounts,
      domains: app.domains,
    };

    setLoading(true);
    appsApi()
      .createApp(projectId, newApp) // Adjust according to your API module
      .then(() => {
        message.success("App created successfully");
        navigate(`/projects/${projectId}/apps`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainLayout showSider={true}>
      <h1>Create New App</h1>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the app's name!" },
            ]}
          >
            <Input
              value={app.name}
              onChange={(e) =>
                setApp((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Item>

          <AppDeploymentConfig app={app} setApp={setApp} />
          <AppReplicas />
          <AppPlan appPricings={appPricings} setApp={setApp} />

          <AppVolumeMounts
            app={app}
            setApp={setApp}
            newVolumeMounts={newVolumeMounts}
            setNewVolumeMounts={setNewVolumeMounts}
          />

          <AppHttpService
            setHasHttpService={setHasHttpService}
            hasHttpService={hasHttpService}
            setIsAppPublic={setIsAppPublic}
            isAppPublic={isAppPublic}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create App
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default CreateApp;
