import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Spin,
  List,
  Button,
  message,
  Select,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Collapse,
} from "antd";
import { App, Event, Log } from "../../api/generated";
import {
  appsApi,
  domainApi,
  logsApi,
  metricsApi,
  regionsApi,
  projectsApi,
  streamAppEventsURL,
  streamAppLogs,
} from "../../api/apiConfig";
import { handleError, formatDeploymentMessage } from "../../utils";
import MainLayout from "../../components/layout";
import EventsList from "../../components/events/EventsList";
import UsageChart from "../../components/metrics/MetricsGraph";
import { MetricSet, GetAppMetricsPeriodEnum } from "../../api/generated";
import LogsViewer from "../../components/logs/LogsViewer";
import ResourceDeleteModal from "../../components/resource-delete-modal/ResourceDeleteModal";
import Title from "antd/es/typography/Title";
import { SyncOutlined, ClockCircleOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Text } = Typography;

const AppDetailsPage = () => {
  const [loading, setLoading] = useState(true);
  const [appDetails, setAppDetails] = useState<App>();
  const [events, setEvents] = useState<Event[]>([]);
  const [logs, setLogs] = useState<Log[]>([]);
  const [cpuUsageData, setCpuUsageData] = useState<MetricSet[]>([]);
  const [ramUsageData, setRamUsageData] = useState<MetricSet[]>([]);
  const [metricPeriod, setMetricPeriod] = useState("168h");
  const [refreshInterval, setRefreshInterval] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newDomain, setNewDomain] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [regionIP, setRegionIP] = useState("");

  const appId = useParams().appId?.toString() || "";
  const projectId = useParams().projectId?.toString() || "";

  const getRegionIP = (region: string) => {
    projectsApi().getProject(region).then((response) => {
      if (response.data.region) {
        regionsApi().getRegion(response.data.region).then((response) => {
          if (response.data.ingressIp) {
            setRegionIP(response.data.ingressIp);
          }
        }
        ).catch((error) => {
          handleError(error);
        });

      }
    }
    ).catch((error) => {
      handleError(error);
    });
  }

  useEffect(() => {
    getRegionIP(projectId);
  }, [projectId]);

  const loadAppDetails = (projectId: string, appId: string) => {
    appsApi()
      .getApp(projectId, appId)
      .then((response) => {
        setAppDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadLogs = (projectId: string, appId: string) => {
    logsApi()
      .getAppLogs(projectId, appId, 1000)
      .then((response) => {
        setLogs(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const loadAppCPUMetrics = (
    projectId: string,
    appId: string,
    metricPeriod: string
  ) => {
    console.log(metricPeriod);
    metricsApi()
      .getAppMetrics(
        projectId,
        appId,
        "cpu",
        metricPeriod as GetAppMetricsPeriodEnum
      )
      .then((response) => {
        setCpuUsageData(response.data || []);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const loadAppRAMMetrics = (
    projectId: string,
    appId: string,
    metricPeriod: string
  ) => {
    console.log(metricPeriod);
    metricsApi()
      .getAppMetrics(
        projectId,
        appId,
        "memory",
        metricPeriod as GetAppMetricsPeriodEnum
      )
      .then((response) => {
        setRamUsageData(response.data || []);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    loadAppDetails(projectId, appId);
    loadLogs(projectId, appId);

    const setupEventSource = (url: string, onData: (data: any) => void) => {
      const eventSource = new EventSource(url, {
        withCredentials: true,
      });
      eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);
        onData(eventData);
      };
      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
      };
      return eventSource;
    };

    const eventSource = setupEventSource(
      streamAppEventsURL(projectId, appId),
      (eventData: Event) => {
        setEvents((prevEvents) => [eventData, ...prevEvents]);
      }
    );

    const logSource = setupEventSource(
      streamAppLogs(projectId, appId),
      (eventData: Log) => {
        setLogs((prevLogs) => [eventData, ...prevLogs]);
      }
    );

    return () => {
      eventSource.close();
      logSource.close();
    };
  }, [appId, projectId]);

  useEffect(() => {
    console.log(metricPeriod);
    loadAppCPUMetrics(projectId, appId, metricPeriod);
    loadAppRAMMetrics(projectId, appId, metricPeriod);
  }, [appId, metricPeriod, projectId]);

  useEffect(() => {
    if (refreshInterval !== null) {
      const interval = setInterval(() => {
        loadAppCPUMetrics(projectId, appId, metricPeriod);
        loadAppRAMMetrics(projectId, appId, metricPeriod);
      }, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [appId, refreshInterval, metricPeriod, projectId]);

  const handleDeleteApp = () => {
    appsApi()
      .deleteApp(projectId, appId)
      .then(() => {
        message.success("App deleted successfully");
        navigate(`/projects/${projectId}/apps`);
        setIsModalVisible(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleRefreshClick = () => {
    loadAppCPUMetrics(projectId, appId, metricPeriod);
    loadAppRAMMetrics(projectId, appId, metricPeriod);
  };

  const handleAutoRefreshChange = (value: string) => {
    const interval = Number(value);
    if (interval === 0) {
      // Check if "No Refresh" is selected
      setRefreshInterval(null); // Stop the auto-refresh
    } else {
      setRefreshInterval(interval);
    }
  };

  const handleMetricPeriodChange = (value: string) => {
    setMetricPeriod(value);
  };

  const [domainsList, setDomainsList] = useState([] as string[]);
  const [currentDomain, setCurrentDomain] = useState(null);
  const [isDomainModalVisible, setIsDomainModalVisible] = useState(false);

  // update domains list when appDetails is changed
  useEffect(() => {
    if (appDetails) {
      setDomainsList(appDetails.domains || []);
    }
  }, [appDetails]);

  const handleAddDomain = (value: string) => {
    domainApi()
      .addDomain(projectId, appId, value)
      .then(() => {
        setDomainsList(value ? [...domainsList, value] : domainsList);
        form.resetFields();
        console.log("Domain added", value, projectId, appId);
        message.success("The domain added successfully");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleDeleteDomain = (domain: string) => {
    domainApi()
      .removeDomain(projectId, appId, domain)
      .then(() => {
        setDomainsList(domainsList.filter((item) => item !== domain));
        console.log("Domain deleted", domain, projectId, appId);
        message.success("The domain deleted successfully");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Function to handle restart
  const handleRestartApp = () => {
    appsApi()
      .restartApp(projectId, appId)
      .then(() => {
        message.success("App restarted successfully");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Function to handle rebuild
  const handleRebuildApp = () => {
    appsApi()
      .rebuildApp(projectId, appId)
      .then(() => {
        message.success("App rebuild has been issued successfully");
      })
      .catch((error) => {
        handleError(error);
      });
  };


  if (loading || !appDetails) {
    return <Spin spinning={true}>Loading...</Spin>;
  }

  const deploymentMessage = formatDeploymentMessage(appDetails.deploymentSource);

  return (
    <MainLayout showSider={true}>
      <h2>{appDetails.name} - Details</h2>
      <Button href={`/projects/${projectId}/apps/${appId}/edit`} type="primary">
        Edit
      </Button>
      <Button onClick={handleRestartApp} type="default" style={{ marginLeft: 8 }}>
        Restart
      </Button>
      {appDetails.deploymentSource.type === "git" && (
        <Button onClick={handleRebuildApp} type="default" style={{ marginLeft: 8 }}>
          Rebuild
        </Button>
      )}
      <ResourceDeleteModal
        handleOk={handleDeleteApp}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        someDetails={{ name: appDetails.name }}
        resourceType={"App"}
      />
      <Row gutter={3}>
        <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
          <Card title="Deployment info and your plan" style={{ flexGrow: 1 }}>
            <p dangerouslySetInnerHTML={{ __html: deploymentMessage }} ></p>
            <p>
              Your plan: <b>{appDetails.plan}</b>
            </p>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Events">
            <EventsList events={events} />
          </Card>
        </Col>
      </Row>
      <Row gutter={3}>
        <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
          <Card title="Domains">
            <Text>
              Use this IP to set your domains: <b>{regionIP}</b><br />
              {appDetails.builtInDomain && (
                <>
                  Your built-in domain: <a href={`https://${appDetails.builtInDomain}`}>{appDetails.builtInDomain}</a>
                </>
              )}
            </Text>
            <Col style={{ marginTop: "15px" }}>
              <Collapse bordered={false}>
                <Panel header="Added domains" key="AddedDomainsPanel">
                  <List
                    bordered
                    dataSource={domainsList}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <Button
                            onClick={() => {
                              setCurrentDomain(item as any);
                              setIsDomainModalVisible(true);
                            }}
                          >
                            Delete
                          </Button>,
                        ]}
                      >
                        {item}
                      </List.Item>
                    )}
                  />

                  {currentDomain && (
                    <ResourceDeleteModal
                      handleOk={() => {
                        handleDeleteDomain(currentDomain);
                        setIsDomainModalVisible(false);
                        setCurrentDomain(null);
                      }}
                      setIsModalVisible={setIsDomainModalVisible}
                      isModalVisible={isDomainModalVisible}
                      someDetails={{ name: currentDomain }}
                      resourceType={"Domain"}
                    />
                  )}
                </Panel>
              </Collapse>
            </Col>
            <Form
              layout="vertical"
              onFinish={() => handleAddDomain(newDomain)}
              style={{ marginTop: "14px" }}
              form={form}
            >
              <Row gutter={8} wrap={false}>
                <Col flex="auto">
                  <Form.Item
                    label="Add new domain"
                    name="newDomain"
                    rules={[
                      { required: true, message: "Please input your domain!" },
                    ]}
                  >
                    <Input
                      placeholder="Enter domain name"
                      value={newDomain}
                      onChange={(e) => setNewDomain(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: "32px" }}
                    >
                      Save Domain
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={12} style={{ display: "flex", flexDirection: "column" }}>
          <Card title="Environment Variables" style={{ flexGrow: 1 }}>
            <List
              dataSource={appDetails.envVars}
              renderItem={(item) => (
                <List.Item>
                  {item.name}: {item.value}
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Card title="Logs">
        <LogsViewer logs={logs} />
      </Card>

      <Card
        title="Metrics"
        extra={
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Select
              defaultValue="168h"
              style={{ width: 150 }}
              onChange={handleMetricPeriodChange}
            >
              <Select.Option value="1h">
                <ClockCircleOutlined /> Last 1 hour
              </Select.Option>
              <Select.Option value="12h">
                <ClockCircleOutlined /> Last 12 hours
              </Select.Option>
              <Select.Option value="48h">
                <ClockCircleOutlined /> Last 2 days
              </Select.Option>
              <Select.Option value="168h">
                <ClockCircleOutlined /> Last 7 days
              </Select.Option>
            </Select>

            <Button onClick={() => handleRefreshClick()}>
              <SyncOutlined />
              Refresh Now
            </Button>

            <Select
              defaultValue="0"
              style={{ width: 120 }}
              onChange={handleAutoRefreshChange}
            >
              <Select.Option value="0">No Refresh</Select.Option>
              <Select.Option value="5000">5s</Select.Option>
              <Select.Option value="30000">30s</Select.Option>
              <Select.Option value="60000">1m</Select.Option>
            </Select>
          </div>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Title level={5}>CPU Usage</Title>
            <UsageChart dataSets={cpuUsageData} />
          </Col>

          <Col span={12}>
            <Title level={5}>RAM Usage</Title>
            <UsageChart dataSets={ramUsageData} />
          </Col>
        </Row>
      </Card>
    </MainLayout>
  );
};

export default AppDetailsPage;
