import React from "react";
import { Form, Input, Select } from "antd";
import { App } from "../../../api/generated";

const { Option } = Select;

interface AppDeploymentConfigProps {
  app: App;
  setApp: React.Dispatch<React.SetStateAction<App>>;
}

const AppDeploymentConfig: React.FC<AppDeploymentConfigProps> = ({
  app,
  setApp,
}) => {

  const DEPLOYMENT_TYPES = [
    { text: "Docker", value: "docker" },
    { text: "Git", value: "git" },
  ];

  return (
    <div>
      <Form.Item
        label="Deployment Type:"
        name={["deploymentSource", "type"]}
        rules={[{ required: true }]}
      >
        <Select
          onChange={(value) =>
            setApp((prev) => ({
              ...prev!,
              deploymentSource: { ...prev?.deploymentSource, type: value },
            }))
          }
        >
          {DEPLOYMENT_TYPES.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {app.deploymentSource.type === "docker" && (
        <>
          <Form.Item
            label="Image:"
            name={["deploymentSource", "docker", "image"]}
            rules={[{ required: true }]}
          >
            <Input placeholder="nginx:latest" />
          </Form.Item>
          <Form.Item
            label="Username:"
            name={["deploymentSource", "docker", "username"]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password:"
            name={["deploymentSource", "docker", "password"]}
          >
            <Input.Password />
          </Form.Item>
        </>
      )}

      {app.deploymentSource.type === "git" && (
        <>
          <Form.Item
            label="URL:"
            name={["deploymentSource", "git", "url"]}
            rules={[{ required: true }]}
          >
            <Input placeholder="git@github.com:example/example.git" />
          </Form.Item>
          <Form.Item
            label="Branch:"
            name={["deploymentSource", "git", "branch"]}
          >
            <Input defaultValue="main" />
          </Form.Item>

          <Form.Item
            label="Token:"
            name={["deploymentSource", "git", "token"]}
          >
            <Input.Password placeholder="Your Git Access Token" />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default AppDeploymentConfig;
