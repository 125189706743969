import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { mysqlApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { MySQL, MySQLPricing } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const EditMySQL: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mySQLDetails, setMySQLDetails] = useState<MySQL>();
  const { projectId = "", mysqlId = "" } = useParams();

  const [mySQLPricings, setMySQLPricings] = useState<MySQLPricing[]>([]);

  useEffect(() => {
    projectsApi().getProject(projectId).then((response) => {
      if (response.data) {
        // check that the region is set
        const region = response.data.region || '';

        // get the app pricing
        regionsApi().getRegionMySQLPricing(region).then((response) => {
          // sort the pricing by price
          response.data.sort((a, b) => a.price - b.price);
          setMySQLPricings(response.data);
        }).catch((error) => {
          handleError(error);
        });
      }
    }).catch((error) => {
      handleError(error);
    });

    loadMySQLDetails(projectId, mysqlId);
  }, [projectId, mysqlId]);

  const loadMySQLDetails = (projectId: string, mysqlId: string) => {
    mysqlApi()
      .getMysql(projectId, mysqlId)
      .then((response) => {
        setMySQLDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (values: MySQL) => {
    setLoading(true);
    mysqlApi()
      .updateMysql(projectId, mysqlId, values)
      .then(() => {
        message.success("MySQL updated successfully");
        navigate(`/projects/${projectId}/mysqls/${mysqlId}`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!mySQLDetails) return <Spin spinning={true}>Loading...</Spin>;

  return (
    <MainLayout showSider={true}>
      <h1>Edit MySQL</h1>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...mySQLDetails,
          }}
        >
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the app's name!" },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item label="Plan:" name="plan" rules={[{ required: true }]}>
            <Select>
              {mySQLPricings.map((plan) => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} - ${plan.type} ${plan.type !== 'shared' ? `(${plan.ram}GB RAM, ${plan.cores} Cores)` : ''
                    }`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update MySQL
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default EditMySQL;
