import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Spin, List, message } from "antd";
import { useParams } from "react-router-dom";
import { projectsApi, globalEnvApi } from "../../api/apiConfig";
import MainLayout from "../../components/layout";
import { Project, EnvVar } from "../../api/generated";
import { handleError } from "../../utils";
import { useNavigate } from "react-router-dom";
import ResourceDeleteModal from "../../components/resource-delete-modal/ResourceDeleteModal";

const ProjectDetailsPage: React.FC = () => {
  const { projectId = "" } = useParams();
  const [projectDetails, setProjectDetails] = useState<Project>();
  const [globalEnvVars, setGlobalEnvVars] = useState<EnvVar[]>([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const resourceType = "Project";
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  useEffect(() => {
    loadProjectDetails(projectId);
    loadGlobalEnvs(projectId);
  }, [projectId]);

  const loadGlobalEnvs = (projectId: string) => {
    globalEnvApi()
      .getGlobalEnv(projectId)
      .then((response) => {
        if (response.data) {
          setGlobalEnvVars(response.data);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const loadProjectDetails = (projectId: string) => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        setProjectDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteProject = (projectId: string) => {
    projectsApi()
      .deleteProject(projectId)
      .then(() => {
        message.success("Project deleted successfully");
        // Redirect to dashboard page
        navigate("/dashboard");
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const onEdit = () => {
    setEditing(true);
  };

  const onCancelEdit = () => {
    setEditing(false);
    form.resetFields();
  };


  const onFinish = (project: Project) => {
    setLoading(true);
    projectsApi()
      .updateProject(projectId, project.name ?? "")
      .then(() => {
        message.success("Project updated successfully");
        setEditing(false);
        let newProject = projectDetails;
        if (newProject) {
          newProject.name = project.name;
        }
        setProjectDetails(newProject);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  if (loading) {
    return <Spin spinning={true}>Loading...</Spin>;
  }

  return (
    <MainLayout showSider={true}>
      <Card title={projectDetails?.id} extra={
        <div>
          <Button onClick={onEdit}>Edit</Button>
          <ResourceDeleteModal
            handleOk={() => deleteProject(projectId)}
            setIsModalVisible={setIsModalDeleteVisible}
            isModalVisible={isModalDeleteVisible}
            resourceType={resourceType}
            someDetails={{ name: projectDetails?.id ?? "" }}
          />
        </div>
      }>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: projectDetails?.name }}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item name="name">
            {editing ? (
              <Input addonBefore="Friendly Name" value={projectDetails?.name} />
            ) : (
              <Input addonBefore="Friendly Name" value={projectDetails?.name} readOnly />
            )}
          </Form.Item>
          <Form.Item>
            {editing ? (
              <Input addonBefore="Region" value={projectDetails?.region} readOnly disabled />
            ) : (
              <Input addonBefore="Region" value={projectDetails?.region} readOnly />
            )}

          </Form.Item>
          {editing && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button onClick={onCancelEdit} style={{ marginLeft: 8 }}>
                Cancel
              </Button>
            </Form.Item>
          )}
        </Form>
      </Card>
      <Card title="Global Environment Variables">
        <List
          dataSource={globalEnvVars}
          renderItem={(item) => (
            <List.Item>
              {item.name}: {item.value}
              {/* Include UI for editing individual environment variables if needed */}
            </List.Item>
          )}
        />
        {/* Add form for adding/updating environment variables */}
      </Card>
    </MainLayout>
  );
};

export default ProjectDetailsPage;
