import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import MainLayout from "../../components/layout";
import { Table, Button, Spin } from "antd";
import { MySQL } from "../../api/generated";
import { mysqlApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import { useNavigate } from 'react-router-dom';


const MySQLsPage: React.FC = () => {
  const [mySQLs, setMySQLs] = useState<MySQL[]>([]);
  const [loading, setLoading] = useState(true);
  const projectId = useParams().projectId?.toString() || "";
  const navigate = useNavigate();

  useEffect(() => {
    loadMySQLs(projectId);
  }, [projectId]);

  const loadMySQLs = (projectId: string) => {
    mysqlApi()
      .getMysqls(projectId)
      .then((response) => {
        setMySQLs(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Tier", dataIndex: "tier", key: "tier" },
    {
      title: "Replicated",
      dataIndex: "replicated",
      key: "replicated",
      render: (replicated: boolean) => (replicated ? "True" : "False"),
    },
  ];

  return (
    <MainLayout showSider={true}>
      <h1>MySQL's</h1>
      <Button type="primary" style={{ marginBottom: 16 }}>
        <Link to={`/projects/${projectId}/mysqls/new`}>Add New SQL</Link>
      </Button>
      {loading ? (
        <Spin />
      ) : (
        <Table
          dataSource={mySQLs}
          columns={columns}
          rowKey="name"
          onRow={(record: MySQL) => {
            return {
              onClick: () => {
                navigate(`/projects/${projectId}/mysqls/${record.name}`);
              },
            };
          }}
        />
      )}
    </MainLayout>
  );
};

export default MySQLsPage;
