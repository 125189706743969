import MainLayout from '../../components/layout';

const PrivacyNotice = () => {

  return (
    <MainLayout>
      <div className="container" style={{ maxWidth: '600px', margin: '40px auto' }}>
        <h1 className="text-center">Privacy Notice</h1>
        <p>We use Stripe for payment, analytics, and other business services. Stripe collects and processes personal data, including identifying information about the devices that connect to its services. Stripe uses this information to operate and improve the services it provides to us, including for fraud detection and prevention. You can learn more about Stripe and its processing activities via privacy policy at https://stripe.com/privacy.</p>
      </div>
    </MainLayout>
  );
};

export default PrivacyNotice;
