import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { redisApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { Redis, RedisPricing } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const CreateRedis: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId = "" } = useParams();
  const [redisPricings, setRedisPricings] = useState<RedisPricing[]>([]);

  const [redis, setRedis] = useState<Redis>({
    name: "",
    plan: "",
  });

  useEffect(() => {
    projectsApi().getProject(projectId).then((response) => {
      if (response.data) {
        // check that the region is set
        const region = response.data.region || '';

        // get the app pricing
        regionsApi().getRegionRedisPricing(region).then((response) => {
          // sort the pricing by price
          response.data.sort((a, b) => a.price - b.price);
          setRedisPricings(response.data);
        }).catch((error) => {
          handleError(error);
        });
      }
    }).catch((error) => {
      handleError(error);
    });

  }, [projectId]);


  const onFinish = (values: Redis) => {
    setLoading(true);
    redisApi()
      .createRedis(projectId, values)
      .then(() => {
        message.success("Redis created successfully");
        navigate(`/projects/${projectId}/redis`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainLayout showSider={true}>
      <h1>Create New Redis</h1>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the Redis name!" },
            ]}
          >
            <Input
              value={redis.name}
              onChange={(e) =>
                setRedis((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Item>

          <Form.Item label="Plan:" name="plan" rules={[{ required: true, message: "Please select a plan!" }]}>
            <Select>
              {redisPricings.map(plan => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} (${plan.storage}GB RAM)`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Redis
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default CreateRedis;
