import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import Confirm from "../pages/auth/confirm";
import Dashboard from "../pages/dashboard";
import NotFound from "../pages/notfound";
import Profile from "../pages/user/Profile";
import Invoices from "../pages/invoices/Invoices";
import { AuthProvider, ProtectedRoute } from "../components/auth";
import AppsPage from "../pages/apps/ListApps";
import CreateApp from "../pages/apps/CreateApp";
import AppDetailsPage from "../pages/apps/ShowApp";
import EditApp from "../pages/apps/EditApp";
import RedisesPage from "../pages/redis/RedisesPage";
import CreateRedis from "../pages/redis/CreateRedis";
import RedisDetailsPage from "../pages/redis/ShowRedis";
import EditRedis from "../pages/redis/EditRedis";
import MySQLsPage from "../pages/mysql/MySQLsPage";
import CreateMySQL from "../pages/mysql/CreateMySQL";
import MySQLDetailsPage from "../pages/mysql/ShowMySQL";
import EditMySQL from "../pages/mysql/EditMySQL";
import Pricing from "../pages/pricing";
import VolumesPage from "../pages/volumes/ListVolumes";
import CreateVolume from "../pages/volumes/CreateVolume";
import VolumeDetailsPage from "../pages/volumes/ShowVolume";
import EditVolume from "../pages/volumes/EditVolume";
import ProjectDetailsPage from "../pages/project/ShowProject";
import PrivacyNotice from "../pages/privacy/PrivacyNotice";
import PaymentUpdate from "../pages/user/PaymentUpdate";
import PaymentStatus from "../pages/user/PaymentStatus";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirm/:token" element={<Confirm />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/payments/update" element={<PaymentUpdate />} />
          <Route path="/profile/payments/status" element={<PaymentStatus />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/projects/:projectId/apps" element={<AppsPage />} />
          <Route path="/projects/:projectId/apps/new" element={<CreateApp />} />
          <Route
            path="/projects/:projectId/"
            element={<ProjectDetailsPage />}
          />
          <Route
            path="/projects/:projectId/apps/:appId"
            element={<AppDetailsPage />}
          />
          <Route
            path="/projects/:projectId/apps/:appId/edit"
            element={<EditApp />}
          />
          <Route path="/projects/:projectId/mysqls" element={<MySQLsPage />} />
          <Route
            path="/projects/:projectId/mysqls/new"
            element={<CreateMySQL />}
          />
          <Route
            path="/projects/:projectId/mysqls/:mysqlId"
            element={<MySQLDetailsPage />}
          />
          <Route
            path="/projects/:projectId/mysqls/:mysqlId/edit"
            element={<EditMySQL />}
          />
          <Route path="/projects/:projectId/redis" element={<RedisesPage />} />
          <Route
            path="/projects/:projectId/redises/new"
            element={<CreateRedis />}
          />
          <Route
            path="/projects/:projectId/redises/:redisId"
            element={<RedisDetailsPage />}
          />
          <Route
            path="/projects/:projectId/redises/:redisId/edit"
            element={<EditRedis />}
          />
          <Route
            path="/projects/:projectId/volumes"
            element={<VolumesPage />}
          />
          <Route
            path="/projects/:projectId/volumes/new"
            element={<CreateVolume />}
          />
          <Route
            path="/projects/:projectId/volumes/:volumeId"
            element={<VolumeDetailsPage />}
          />
          <Route
            path="/projects/:projectId/volumes/:volumeId/edit"
            element={<EditVolume />}
          />
        </Route>
        <Route path="/privacy" element={<PrivacyNotice />} />
        <Route path="/pricing" element={<Pricing />}>
          "Pricing"
        </Route>
        <Route path="*" element={<NotFound />} />{" "}
        {/* Updated to use NotFound */}
      </Routes>
    </AuthProvider>
  );
}

export default App;
