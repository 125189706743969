import React from "react";
import { Checkbox, Form, InputNumber } from "antd";

interface AppHttpServiceProps {
  setHasHttpService: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAppPublic: React.Dispatch<React.SetStateAction<boolean>>;
  hasHttpService: boolean;
  isAppPublic: boolean;
}

const AppHttpService: React.FC<AppHttpServiceProps> = ({
  setHasHttpService,
  setIsAppPublic,
  hasHttpService,
  isAppPublic,
}) => {
  return (
    <>
      <Form.Item>
        <Checkbox
          checked={hasHttpService}
          onChange={(e) => setHasHttpService(e.target.checked)}
        >
          Does this app have HTTP Service?
        </Checkbox>
      </Form.Item>

      {hasHttpService && (
        <>
          <Form.Item
            label="HTTP Port:"
            name="httpPort"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="80" />
          </Form.Item>
          <Form.Item name="public" valuePropName="checked">
            <Checkbox
              checked={isAppPublic}
              onChange={(e) => setIsAppPublic(e.target.checked)}
            >
              Is this app public?
            </Checkbox>
          </Form.Item>
        </>
      )}
    </>
  );
};

export default AppHttpService;
