import React from 'react';
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Button, Form, message } from 'antd';
import { handleError } from '../../utils';

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!stripe || !elements) {
      handleError("Stripe.js has not loaded yet. Please try again.");
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // TODO: Replace with actual URL from environment variables
        return_url: window.env.REACT_APP_FRONTEND_URL + '/profile/payments/status',
      },
    });

    if (result.error) {
      const errorMessage = result.error.message || "An error occurred during setup";
      handleError(errorMessage);
    } else {
      message.success("Setup process is complete. Please check your email for confirmation.");
    }
  };

  return (
    <Form onFinish={handleSubmit}>
      <PaymentElement />
      <Form.Item>
        <Button disabled={!stripe} type="primary" htmlType="submit">
          Add Payment Method
        </Button>

      </Form.Item>
    </Form>
  );
};

export default SetupForm;
