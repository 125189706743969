import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { projectsApi, regionsApi, volumesApi } from "../../api/apiConfig";
import { Volume, VolumePricing } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const EditVolume: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [volumeDetails, setVolumeDetails] = useState<Volume>();

  const { projectId = "", volumeId = "" } = useParams();

  const [volumePricing, setVolumePricing] = useState<VolumePricing[]>([]);

  useEffect(() => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        if (response.data) {
          // check that the region is set
          const region = response.data.region || "";

          // get the volume pricing
          regionsApi()
            .getRegionVolumePricing(region)
            .then((response) => {
              // sort the pricing by price
              response.data.sort((a, b) => a.price - b.price);
              setVolumePricing(response.data);
            })
            .catch((error) => {
              handleError(error);
            });
        }
      })
      .catch((error) => {
        handleError(error);
      });

    loadVolumeDetails(projectId, volumeId);
  }, [projectId, volumeId]);

  const loadVolumeDetails = (projectId: string, volumeId: string) => {
    volumesApi()
      .getVolume(projectId, volumeId)
      .then((response) => {
        setVolumeDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (values: Volume) => {
    setLoading(true);
    volumesApi()
      .updateVolume(projectId, volumeId, values)
      .then(() => {
        message.success("Volume updated successfully");
        navigate(`/projects/${projectId}/volumes/`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!volumeDetails) return <Spin spinning={true}>Loading...</Spin>;

  return (
    <MainLayout showSider={true}>
      <h1>Edit Volume</h1>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...volumeDetails,
          }}
        >
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the app's name!" },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item label="Plan:" name="plan" rules={[{ required: true }]}>
            <Select>
              {volumePricing.map((plan) => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} - ${plan.size} GB`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Volume
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default EditVolume;
