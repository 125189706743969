import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Input } from "antd";

interface ResourceDeleteModalProps {
  handleOk: () => void;
  setIsModalVisible: (value: boolean) => void;
  isModalVisible: boolean;
  someDetails: { name: string };
  resourceType?: string;
}

const ResourceDeleteModal: React.FC<ResourceDeleteModalProps> = ({
  handleOk,
  setIsModalVisible,
  isModalVisible,
  someDetails,
  resourceType,
}) => {
  const [inputedAppName, setInputedAppName] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputedAppName("");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputedAppName(e.target.value);
  };

  return (
    <>
      <Button
        style={{ marginLeft: "15px" }}
        icon={<DeleteOutlined />}
        onClick={showModal}
      />
      <Modal
        title={`Delete '${someDetails.name}'?`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ disabled: inputedAppName !== someDetails.name }}
      >
        <p>
          This action is permanent! Confirm the deletion by inputing the name of
          the {resourceType} in the Input box below.
        </p>
        <Input
          value={inputedAppName}
          onChange={handleInputChange}
          style={{
            display: "block",
            margin: "20px auto",
            width: "80%",
          }}
        />
      </Modal>
    </>
  );
};

export default ResourceDeleteModal;
