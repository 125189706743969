// src/config.js
import { loadStripe } from '@stripe/stripe-js';
import { BASE_PATH } from './api/generated/base';

// Helper function to determine the current environment
const isDevelopment = process.env.NODE_ENV === 'development';

// Function to get the Stripe key based on the environment
const getStripeKey = () => {
  return isDevelopment
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
    : window.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
};

// Function to get the Backend URL based on the environment
const getBackendUrl = () => {
  return isDevelopment
    ? process.env.REACT_APP_BACKEND_URL || BASE_PATH
    : window.env.REACT_APP_BACKEND_URL || BASE_PATH;
};

// Initialize Stripe with the appropriate key
export const stripePromise = loadStripe(getStripeKey());

// Export the configuration variables
export const BACKEND_URL = getBackendUrl();
