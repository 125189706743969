import React, { useState } from "react";
import { usersApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import { Form, Input, Button, Alert } from 'antd';


const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [token, setToken] = useState("");
  const [form] = Form.useForm();

  const onFinish = () => {
    usersApi().createUser({ email, password })
      .then((response) => {
        const data = response.data;

        if (data.message.split(" ").length === 1) {
          setConfirmation("Please click the link below to confirm your registration.");
          setToken(data.message);
          form.resetFields(); // Reset form fields after successful registration
        } else {
          setConfirmation(data.message);
        }
      })
      .catch((error) => {
        handleError(error)
      });
  };

  return (
    <div className="container" style={{ maxWidth: '400px', margin: '40px auto' }}>
      <h1 className="text-center">Register</h1>

      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!', type: 'email' }]}
        >
          <Input placeholder="Email" onChange={e => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password placeholder="Password" onChange={e => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your Password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>

        {confirmation && (
          <Alert message={confirmation} type="success" />
        )}

        {token && (
          <p>
            <a href={`/confirm/${token}`}>Confirm Email</a>
          </p>
        )}

        <p className="text-center">
          Already registered? <a href="/login">Sign in</a>
        </p>
      </Form>
    </div>
  );
};

export default Register;
