import { BACKEND_URL } from "../config";
import {
  Configuration,
  UsersApi,
  ProjectsApi,
  RegionsApi,
  AppsApi,
  VolumeApi,
  MetricsApi,
  LogsApi,
  MysqlApi,
  RedisApi,
  GlobalenvApi,
  InvoicesApi,
  PaymentMethodsApi,
  DomainApi,
} from "./generated";

const config = new Configuration({
  basePath: BACKEND_URL,
  baseOptions: {
    credentials: "include",
    withCredentials: true,
  },
});

export const usersApi = () => new UsersApi(config);
export const projectsApi = () => new ProjectsApi(config);
export const regionsApi = () => new RegionsApi(config);
export const appsApi = () => new AppsApi(config);
export const metricsApi = () => new MetricsApi(config);
export const logsApi = () => new LogsApi(config);
export const redisApi = () => new RedisApi(config);
export const mysqlApi = () => new MysqlApi(config);
export const volumesApi = () => new VolumeApi(config);
export const globalEnvApi = () => new GlobalenvApi(config);
export const invoicesApi = () => new InvoicesApi(config);
export const paymentMethodsApi = () => new PaymentMethodsApi(config);
export const domainApi = () => new DomainApi(config);

export const streamAppEventsURL = (projectId: string, appId: string) => {
  return `${BACKEND_URL}/api/projects/${projectId}/apps/${appId}/events/stream`;
};

export const streamAppLogs = (projectId: string, appId: string) => {
  return `${BACKEND_URL}/api/projects/${projectId}/apps/${appId}/logs/stream`;
};
