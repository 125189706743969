import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { redisApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { Redis, RedisPricing } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const EditRedis: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [redisDetails, setRedisDetails] = useState<Redis>();
  const { projectId = "", redisId = "" } = useParams();
  const [redisPricings, setRedisPricings] = useState<RedisPricing[]>([]);

  useEffect(() => {
    projectsApi().getProject(projectId).then((response) => {
      if (response.data) {
        // check that the region is set
        const region = response.data.region || '';

        // get the app pricing
        regionsApi().getRegionRedisPricing(region).then((response) => {
          response.data.sort((a, b) => a.price - b.price);
          setRedisPricings(response.data);
        }).catch((error) => {
          handleError(error);
        });
      }
    }).catch((error) => {
      handleError(error);
    });

    loadRedisDetails(projectId, redisId);
  }, [projectId, redisId]);

  const loadRedisDetails = (projectId: string, redisId: string) => {
    redisApi()
      .getRedis(projectId, redisId)
      .then((response) => {
        setRedisDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (values: Redis) => {
    setLoading(true);
    redisApi()
      .updateRedis(projectId, redisId, values)
      .then(() => {
        message.success("Redis updated successfully");
        navigate(`/projects/${projectId}/redises/${redisId}`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!redisDetails) return <Spin spinning={true}>Loading...</Spin>;

  return (
    <MainLayout showSider={true}>
      <h1>Edit Redis</h1>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            ...redisDetails,
          }}
        >
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the Redis name!" },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item label="Plan:" name="plan" rules={[{ required: true, message: "Please select a plan!" }]}>
            <Select>
              {redisPricings.map(plan => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} (${plan.storage}GB RAM)`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Redis
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default EditRedis;
