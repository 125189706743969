import React from "react";
import { Form, Select } from "antd";

const { Option } = Select;

const AppReplicas: React.FC = () => {
  const REPLICAS = [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
  ];

  return (
    <div>
      <Form.Item label="Replicas:" name="replicas" rules={[{ required: true }]}>
        <Select>
          {REPLICAS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AppReplicas;
