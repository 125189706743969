import React, { useEffect, useState } from 'react'
import { regionsApi } from '../../api/apiConfig'
import { handleError } from '../../utils'
import MainLayout from '../../components/layout';
import { AppPricing, MySQLPricing, RedisPricing, VolumePricing } from '../../api/generated';
import { Tabs, Select, Typography } from 'antd';
import AppPricingComponent from '../../components/pricing/AppPricing';
import MySQLPricingComponent from '../../components/pricing/MySQLPricing';
import RedisPricingComponent from '../../components/pricing/RedisPricing';
import StoragePricingComponent from '../../components/pricing/VolumePricing';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

const Pricing = () => {
  const [regions, setRegions] = useState<string[]>([]); // State to hold the regions
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [appPricings, setAppPricings] = useState<AppPricing[]>([]);
  const [mySQLPricings, setMySQLPricings] = useState<MySQLPricing[]>([]);
  const [redisPricings, setRedisPricings] = useState<RedisPricing[]>([]);
  const [storagePricings, setStoragePricings] = useState<VolumePricing[]>([]);

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRegions = () => {
    regionsApi().getRegions().then((response) => {
      setRegions(response.data);
      if (response.data.length > 0) {
        const defaultRegion = response.data[0];
        setSelectedRegion(defaultRegion);
        fetchAllPricing(defaultRegion);
      }
    }).catch((error) => {
      handleError(error);
    });
  }

  const fetchAllPricing = (region: string) => {
    getAppPricings(region);
    getMySQLPricings(region);
    getRedisPricings(region);
    getVolumePricings(region);
  }
  const getAppPricings = (region: string) => {
    regionsApi().getRegionAppPricing(region).then((response) => {
      setAppPricings(response.data);
    }).catch((error) => {
      handleError(error);
    });
  }

  const getMySQLPricings = (region: string) => {
    regionsApi().getRegionMySQLPricing(region).then((response) => {
      setMySQLPricings(response.data);
    }).catch((error) => {
      handleError(error);
    });
  }

  const getRedisPricings = (region: string) => {
    regionsApi().getRegionRedisPricing(region).then((response) => {
      setRedisPricings(response.data);
    }).catch((error) => {
      handleError(error);
    });
  }

  const getVolumePricings = (region: string) => {
    regionsApi().getRegionVolumePricing(region).then((response) => {
      setStoragePricings(response.data);
    }).catch((error) => {
      handleError(error);
    });
  }

  return (
    <MainLayout>
      <Title level={2}>Pricing</Title>
      <Select
        style={{ width: 200, marginBottom: 20 }}
        placeholder="Select a region"
        onChange={value => setSelectedRegion(value)}
        value={selectedRegion}
      >
        {regions.map(region => (
          <Option key={region} value={region}>{region}</Option>
        ))}
      </Select>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Apps" key="1">
          <AppPricingComponent appPricings={appPricings} />
        </TabPane>
        <TabPane tab="MySQL" key="2">
          <MySQLPricingComponent mySQLPricings={mySQLPricings} />
        </TabPane>
        <TabPane tab="Redis" key="3">
          <RedisPricingComponent redisPricings={redisPricings} />
        </TabPane>
        <TabPane tab="Volume" key="4">
          <StoragePricingComponent storagePricings={storagePricings} />
        </TabPane>
        {/* Tabs for MySQL, Redis, Volume... */}
      </Tabs>    </MainLayout>
  );
};
export default Pricing
