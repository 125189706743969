import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { volumesApi, regionsApi, projectsApi } from "../../api/apiConfig";
import { VolumePricing, Volume } from "../../api/generated";
import { handleError } from "../../utils";

const { Option } = Select;

const CreateVolume: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { projectId = "" } = useParams();
  const [volumePricings, setVolumePricings] = useState<VolumePricing[]>([]);
  const [volume, setVolume] = useState<Volume>({
    name: "",
    plan: "",
  });

  useEffect(() => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        if (response.data) {
          // check that the region is set
          const region = response.data.region || "";

          // get the volume pricing
          regionsApi()
            .getRegionVolumePricing(region)
            .then((response) => {
              // sort the pricing by price
              response.data.sort((a, b) => a.price - b.price);

              setVolumePricings(response.data);
            })
            .catch((error) => {
              handleError(error);
            });
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }, [projectId]);

  const onFinish = (values: Volume) => {
    setLoading(true);
    volumesApi()
      .createVolume(projectId, values)
      .then(() => {
        message.success("Volume created successfully");
        navigate(`/projects/${projectId}/volumes`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainLayout showSider={true}>
      <h1>Create New Volume</h1>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the Volume name!" },
            ]}
          >
            <Input
              value={volume.name}
              onChange={(e) =>
                setVolume((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </Form.Item>
          <Form.Item label="Plan:" name="plan" rules={[{ required: true }]}>
            <Select>
              {volumePricings.map((plan) => (
                <Option key={plan.name} value={plan.name}>
                  {`${plan.name} - €${plan.price} - ${plan.size} GB`}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Volume
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default CreateVolume;
