import { Button, Card, Spin, message, List } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { volumesApi, appsApi, regionsApi, projectsApi } from "../../api/apiConfig";
import { Volume, App, VolumePricing } from "../../api/generated";
import { handleError } from "../../utils";
import MainLayout from "../../components/layout";
import ResourceDeleteModal from "../../components/resource-delete-modal/ResourceDeleteModal";

const VolumeDetailsPage = () => {
  const { projectId = "", volumeId = "" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [volumeDetails, setVolumeDetails] = useState<Volume>();
  const [appsUsingVolume, setAppsUsingVolume] = useState<App[]>([]);
  const [planDetails, setPlanDetails] = useState<VolumePricing | null>(null);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    volumesApi().getVolume(projectId, volumeId)
      .then(volumeResponse => {
        setVolumeDetails(volumeResponse.data);
        return appsApi().getApps(projectId);
      })
      .then(appsResponse => {
        let apps = appsResponse.data || [];
        const filteredApps = apps.filter(app =>
          app.volumeMounts?.some(vm => vm.name === volumeId)
        );
        setAppsUsingVolume(filteredApps);
        return projectsApi().getProject(projectId);
      })
      .then(projectResponse => {
        const region = projectResponse.data.region;
        if (!region) throw new Error("Region not set for project");
        return regionsApi().getRegionVolumePricing(region);
      })
      .then(pricingResponse => {
        const foundPlan = pricingResponse.data.find(plan => plan.name === volumeDetails?.plan);
        setPlanDetails(foundPlan || null);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => setLoading(false));
  }, [projectId, volumeDetails?.plan, volumeId]);

  const handleDeleteVolume = () => {
    volumesApi().deleteVolume(projectId, volumeId)
      .then(() => {
        message.success("Volume deleted successfully");
        navigate(`/projects/${projectId}/volumes`);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => setIsModalDeleteVisible(false));
  };

  if (loading) return <Spin spinning>Fetching Volume Details...</Spin>;
  if (!volumeDetails) return <div>Volume details not found.</div>;

  const currentUsagePlaceholder = "TBD"; // Placeholder for current usage until implemented

  return (
    <MainLayout showSider>
      <h2>{volumeDetails.name} - Details</h2>
      <Button href={`/projects/${projectId}/volumes/${volumeId}/edit`} type="primary">
        Edit
      </Button>
      <ResourceDeleteModal
        handleOk={handleDeleteVolume}
        setIsModalVisible={setIsModalDeleteVisible}
        isModalVisible={isModalDeleteVisible}
        resourceType="Volume"
        someDetails={{ name: volumeDetails.name }}
      />
      <Card title="Volume Details" style={{ marginTop: "20px" }}>
        <List>
          <List.Item>
            Volume Plan: {planDetails ? `${planDetails.name} (${planDetails.size}GB)` : "Plan details unavailable"}
          </List.Item>
          <List.Item>Current Usage: {currentUsagePlaceholder}</List.Item>
          <List.Item>
            Used in Apps: {
              appsUsingVolume.length > 0
                ? appsUsingVolume.map((app, index) => (
                  <React.Fragment key={app.name}>
                    <Link to={`/projects/${projectId}/apps/${app.name}`}>{app.name}</Link>
                    {index < appsUsingVolume.length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))
                : 'None'
            }
          </List.Item>
        </List>
      </Card>
    </MainLayout>
  );
};

export default VolumeDetailsPage;
