import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { usersApi } from '../../api/apiConfig';
import { handleError } from '../../utils';

// Updated AuthContextType to reflect isAuthenticated's new return type
interface AuthContextType {
  isAuthenticated: () => Promise<boolean>; // Now returns a Promise<boolean>
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Updated isAuthenticated to async function returning a Promise<boolean>
  const isAuthenticated = async (): Promise<boolean> => {
    try {
      await usersApi().getUser();
      return true;
    } catch {
      return false;
    }
  };

  const logout = () => {
    usersApi().logoutUser().then(() => { }).catch((error) => {
      handleError(error);
    });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Updated ProtectedRoute to handle asynchronous isAuthenticated
export const ProtectedRoute: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await auth.isAuthenticated();
      setIsAuthenticated(authStatus);
      setIsAuthChecked(true);
    };

    checkAuth();
  }, [auth]);

  // Only navigate to login if auth has been checked and user is not authenticated
  if (!isAuthChecked) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};
