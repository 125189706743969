import React from 'react';
import { List, Tag } from 'antd';
import moment from 'moment';
import { Event, EventTypeEnum } from '../../api/generated'; // Adjust the import path as needed

interface EventsListProps {
  events: Event[];
  maxEventsToShow?: number;
}

const EventsList: React.FC<EventsListProps> = ({ events, maxEventsToShow = 5 }) => {
  // Step 1: Sort events by timestamp in descending order (newest first)
  const sortedEvents = [...events].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  // Step 2: Limit the number of events to show to the latest ones
  const limitedEvents = sortedEvents.slice(0, maxEventsToShow);

  // Step 3: Reverse the order of the limited array for display (oldest at top, newest at bottom)
  const eventsForDisplay = limitedEvents.reverse();

  return (
    <List
      itemLayout="horizontal"
      dataSource={eventsForDisplay}
      renderItem={(event, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            title={
              <>
                {event.type === EventTypeEnum.Warning ? <Tag color="volcano">Warning</Tag> : <Tag color="green">Info</Tag>}
                {moment(event.timestamp).fromNow()}
              </>
            }
            description={event.message}
          />
          <div style={{ marginTop: '0.5rem' }}>
            <Tag color="blue">{event.reason}</Tag>
          </div>
        </List.Item>
      )}
    />
  );
};

export default EventsList;
