import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { MetricSet } from '../../api/generated';

interface Props {
  dataSets: MetricSet[]; // Array of datasets
  height?: string; // Optional height for the chart
}

// Updated to generate a more compact date string format
const formatDate = (date: string) => {
  const d = new Date(date);
  // Example format: "10/31 14:00". Adjust according to your needs
  return `${d.getMonth() + 1}/${d.getDate()} ${d.getHours()}:${('0' + d.getMinutes()).slice(-2)}`;
}

const UsageChart: React.FC<Props> = ({ dataSets, height = '400px' }) => {
  // sort the dataSets by timestamp
  dataSets.forEach((dataset) => {
    dataset.metrics.sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
  });

  // Transform dataSets to fit Nivo's data structure
  const nivoData = dataSets.map(dataset => ({
    id: dataset.name,
    data: dataset.metrics.map(point => ({
      x: formatDate(point.timestamp),
      y: point.value,
    })),
  }));

  // Custom Tooltip Component
  const CustomTooltip = ({ point }: { point: any }) => {
    return (
      <div style={{ background: 'white', padding: '12px', border: '1px solid #ccc' }}>
        <div><strong>Time:</strong> {point.data.xFormatted}</div>
        <div><strong>Usage:</strong> {point.data.yFormatted}%</div>
        {/* You can add more information or style it as needed */}
      </div>
    );
  };

  const ThresholdLayer = ({ yScale, innerWidth }: { yScale: any, innerWidth: any }) => {
    const over80 = yScale(80); // Calculate y position for 80% threshold
    const over90 = yScale(90); // Calculate y position for 90% threshold

    return (
      <>
        {/* Yellow threshold area */}
        <rect
          x={0}
          y={over90}
          width={innerWidth}
          height={over80 - over90}
          fill="rgba(255, 255, 0, 0.05)" // Semi-transparent yellow
        />
        {/* Red threshold area */}
        <rect
          x={0}
          y={0}
          width={innerWidth}
          height={over90}
          fill="rgba(255, 0, 0, 0.1)" // Semi-transparent red
        />
      </>
    );
  };

  return (
    <div style={{ height }}>
      <ResponsiveLine
        data={nivoData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'time', format: '%m/%d %H:%M' }}
        xFormat="time:%m/%d %H:%M"
        yScale={{ type: 'linear', min: 0, max: 100, stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        enableArea={true}
        colors={{ scheme: 'category10' }}
        areaOpacity={0.1}
        axisBottom={{
          format: '%H:%M', // Show only hour and minute
          // tickValues: 'every 2 hours', // Adjust based on your data's span
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45, // Rotate ticks to fit more
          legend: 'Time',
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Usage',
          legendOffset: -40,
          legendPosition: 'middle',
          format: (value: number) => `${value}%`
        }}
        curve='basis'
        enablePoints={false}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        tooltip={CustomTooltip}
        layers={['grid', 'markers', 'axes', 'areas', ThresholdLayer, 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
      />
    </div>
  );
};

export default UsageChart;
