import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Spin, message } from "antd";
import MainLayout from "../../components/layout";
import { appsApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import { App, AppPricing, AppVolumeMountsInner } from "../../api/generated";
import AppPlan from "../../components/apps/forms/AppPlan";
import AppReplicas from "../../components/apps/forms/AppReplicas";
import AppDeploymentConfig from "../../components/apps/forms/AppDeploymentConfig";
import AppHttpService from "../../components/apps/forms/AppHttpService";
import AppVolumeMounts from "../../components/apps/forms/AppVolumeMounts";

const EditApp: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const projectId = useParams().projectId?.toString() || "";
  const appId = useParams().appId?.toString() || "";
  const [app, setApp] = useState<App>({
    name: "",
    deploymentSource: {
      type: "docker",
      docker: {
        image: "",
        username: "",
        password: "",
      },
      git: {
        url: "",
        branch: "",
        token: "",
      },
    },
    replicas: 0,
    public: false,
    plan: "shared",
    volumeMounts: [],
    domains: [] as string[],
  });

  const [newVolumeMounts, setNewVolumeMounts] = useState<AppVolumeMountsInner>(
    {}
  );

  const [hasHttpService, setHasHttpService] = useState(false);
  const [isAppPublic, setIsAppPublic] = useState(false);

  const [appPricings, setAppPricings] = useState<AppPricing[]>([]);
  const [form] = Form.useForm(); // Added useForm hook to get form instance

  useEffect(() => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        if (response.data) {
          // check that the region is set
          const region = response.data.region || "";

          // get the app pricing
          regionsApi()
            .getRegionAppPricing(region)
            .then((response) => {
              // sort the app pricing by price
              response.data.sort((a, b) => a.price - b.price);

              setAppPricings(response.data);
            })
            .catch((error) => {
              handleError(error);
            });
        }
      })
      .catch((error) => {
        handleError(error);
      });

    loadAppDetails(projectId, appId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, appId, form]);

  const loadAppDetails = (projectId: string, appId: string) => {
    appsApi()
      .getApp(projectId, appId)
      .then((response) => {
        if (response.data.volumeMounts === null) {
          response.data.volumeMounts = [];
        }

        setApp(response.data);

        if (response.data.httpPort != null) {
          setHasHttpService(true);
        }

        if (response.data.public != null) {
          setIsAppPublic(true);
        }

        form.setFieldsValue(response.data); // Update form fields with loaded data
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = async (values: App) => {
    const newApp = {
      ...values,
      volumeMounts: app?.volumeMounts || [],
      domains: app?.domains || [],
    };

    setLoading(true);
    appsApi()
      .updateApp(projectId, appId, newApp)
      .then(() => {
        message.success("App updated successfully!");
        navigate(`/projects/${projectId}/apps/${appId}`);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!app) return <Spin spinning={true}>Loading...</Spin>;

  return (
    <MainLayout showSider={true}>
      <h1>Edit App</h1>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={app}
        >
          <Form.Item
            label="Name:"
            name="name"
            rules={[
              { required: true, message: "Please input the app's name!" },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <AppDeploymentConfig app={app} setApp={setApp} />
          <AppReplicas />
          <AppPlan appPricings={appPricings} setApp={setApp} />
          <AppVolumeMounts
            app={app}
            setApp={setApp}
            newVolumeMounts={newVolumeMounts}
            setNewVolumeMounts={setNewVolumeMounts}
          />
          <AppHttpService
            setHasHttpService={setHasHttpService}
            hasHttpService={hasHttpService}
            setIsAppPublic={setIsAppPublic}
            isAppPublic={isAppPublic}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update App
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MainLayout>
  );
};

export default EditApp;
