import React from 'react';
import { Col, Row, Typography, Table } from 'antd';
import { AppPricing } from '../../api/generated';

const { Title } = Typography;

interface AppPricingComponentProps {
  appPricings: AppPricing[];
}

const AppPricingComponent: React.FC<AppPricingComponentProps> = ({ appPricings }) => {
  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price (€)',
      dataIndex: 'price',
      key: 'price',
      render: (text: number) => `€ ${text}`,
    },
    {
      title: 'RAM (GB)',
      dataIndex: 'ram',
      key: 'ram',
    },
    {
      title: 'Cores',
      dataIndex: 'cores',
      key: 'cores',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1),
    },
  ];

  const getDataSource = () => {
    return appPricings
      .sort((a, b) => a.price - b.price)
      .map(plan => ({
        key: plan.name,
        name: plan.name,
        price: plan.price,
        ram: plan.ram,
        cores: plan.cores,
        type: plan.type.charAt(0).toUpperCase() + plan.type.slice(1),
      }));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>App Plans</Title>
        <Table dataSource={getDataSource()} columns={columns} pagination={false} />
      </Col>
    </Row>
  );
};

export default AppPricingComponent;
