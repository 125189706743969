import React, { useEffect, useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import MainLayout from '../../components/layout';
import { paymentMethodsApi } from '../../api/apiConfig';
import { handleError } from '../../utils';
import { Spin } from 'antd';
import SetupForm from '../../components/stripe/SetupForm'; // Assuming SetupForm is another component
import { stripePromise } from '../../config';

const PaymentUpdate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    paymentMethodsApi().createPaymentMethod().then((response) => {
      if (response.data) {
        setClientSecret(response.data.message);
      } else {
        handleError('Failed to fetch payment information');
      }
    }).catch((error) => {
      handleError('Failed to fetch payment information');
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const options: StripeElementsOptions = {
    clientSecret,
    locale: 'en',
  };

  return (
    <MainLayout>
      <div className="container" style={{ maxWidth: '600px', margin: '40px auto' }}>
        <h1 className="text-center">Update Payment Method</h1>
        <Spin spinning={loading}>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <SetupForm />
            </Elements>
          ) : (
            <p>Loading payment information...</p>
          )}
        </Spin>
      </div>
    </MainLayout>
  );
};

export default PaymentUpdate;
