import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Spin,
  Tooltip,
  Typography,
  Row,
  Col,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { mysqlApi } from "../../api/apiConfig";
import { MySQL, MySQLCredentials, MySQLStatus } from "../../api/generated";
import { handleError } from "../../utils";
import MainLayout from "../../components/layout";
import CopyCredentialsModal from "../../components/mysql/CopyCredentialsModal";
import ResourceDeleteModal from "../../components/resource-delete-modal/ResourceDeleteModal";

const MySQLDetailsPage = () => {
  const { projectId = "", mysqlId = "" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [mySQLDetails, setMySQLDetails] = useState<MySQL>();
  const [mySQLCredentials, setMySQLCredentials] = useState<MySQLCredentials>();
  const [mySQLStatus, setMySQLStatus] = useState<MySQLStatus>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resourceType] = useState("MySQL");
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  useEffect(() => {
    loadMySQLDetails(projectId, mysqlId);
    loadMySQLCredentials(projectId, mysqlId);
    loadMySQLStatus(projectId, mysqlId);
  }, [projectId, mysqlId]); // Only re-run the effect if projectId or mysqlId changes

  const loadMySQLDetails = (projectId: string, mysqlId: string) => {
    mysqlApi()
      .getMysql(projectId, mysqlId)
      .then((response) => {
        setMySQLDetails(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMySQLCredentials = (projectId: string, mysqlId: string) => {
    mysqlApi()
      .getMysqlCredentials(projectId, mysqlId)
      .then((response) => {
        setMySQLCredentials(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const loadMySQLStatus = (projectId: string, mysqlId: string) => {
    mysqlApi()
      .getMysqlStatus(projectId, mysqlId)
      .then((response) => {
        setMySQLStatus(response.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => setIsModalVisible(false);

  const handleDeleteMySQL = () => {
    mysqlApi()
      .deleteMysql(projectId, mysqlId)
      .then(() => {
        message.success("MySQL deleted successfully");
        navigate(`/projects/${projectId}/mysqls`);
        setIsModalVisible(false);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  if (loading || !mySQLDetails) {
    return <Spin spinning={true}>Loading...</Spin>;
  }

  return (
    <MainLayout showSider={true}>
      <h2>{mySQLDetails.name} - Details</h2>
      <Button
        href={`/projects/${projectId}/mysqls/${mysqlId}/edit`}
        type="primary"
      >
        Edit
      </Button>
      <ResourceDeleteModal
        handleOk={handleDeleteMySQL}
        setIsModalVisible={setIsModalDeleteVisible}
        isModalVisible={isModalDeleteVisible}
        resourceType={resourceType}
        someDetails={{ name: mySQLDetails.name }}
      />
      <Row gutter={16}>
        <Col span={12}>
          {/* MySQL Credentials & Actions */}
          <Card
            title="Credentials"
            extra={
              <Space>
                <Tooltip title="Copy credentials as environment variables">
                  <Button icon={<CopyOutlined />} onClick={showModal} />
                </Tooltip>
              </Space>
            }
          >
            <Form layout="vertical">
              <Form.Item>
                <Input
                  addonBefore="Hostname"
                  value={mySQLCredentials?.hostname}
                  readOnly
                />
              </Form.Item>
              <Form.Item>
                <Input
                  addonBefore="Port"
                  value={mySQLCredentials?.port}
                  readOnly
                />
              </Form.Item>
              <Form.Item>
                <Input
                  addonBefore="Username"
                  value={mySQLCredentials?.username}
                  readOnly
                />
              </Form.Item>
              <Form.Item>
                <Input.Password
                  addonBefore="Password"
                  value={mySQLCredentials?.password}
                  readOnly
                />
              </Form.Item>
              <Form.Item>
                <Input
                  addonBefore="Database"
                  value={mySQLCredentials?.database}
                  readOnly
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={12}>
          {/* MySQL Status */}
          <Card title="Status">
            <Typography.Paragraph>
              Used MB: {mySQLStatus?.usedMB}
            </Typography.Paragraph>
            <Typography.Paragraph>
              Role: {mySQLStatus?.role}
            </Typography.Paragraph>
            <Typography.Paragraph>
              Migration Status: {mySQLStatus?.migrationStatus}
            </Typography.Paragraph>
            <Typography.Paragraph>
              Created: {mySQLStatus?.created ? "Yes" : "No"}
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>

      {/* Copy MySQL Credentials Modal */}
      <CopyCredentialsModal
        prefix={mysqlId}
        mySQLCredentials={mySQLCredentials}
        open={isModalVisible}
        onClose={handleCloseModal}
      />
    </MainLayout>
  );
};

export default MySQLDetailsPage;
