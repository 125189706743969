import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { usersApi } from "../../api/apiConfig";
import { handleError } from "../../utils";
import { Button, Spin, Alert } from "antd";

const Confirm: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);

  const handleConfirmation = async () => {
    if (!token) {
      setIsLoading(false);
      handleError("Invalid confirmation token.");
      return;
    }
    setIsLoading(true);
    usersApi().confirmUser(token)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(false);
        handleError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="container" style={{ maxWidth: '400px', margin: '40px auto' }}>
      <h1 className="text-center">Confirm your account</h1>
      <p className="text-center">Please click the button below to confirm your account.</p>

      {isLoading ? (
        <Spin size="large" />
      ) : success === true ? (
        <>
          <Alert message="Your account has been successfully confirmed. Now you can login." type="success" showIcon />
          <Button type="primary" style={{ marginTop: "20px" }}>
            <Link to="/login">Go to Login</Link>
          </Button>
        </>
      ) : success === false ? (
        <Alert message="Failed to confirm your account. Please try the link in your email again or contact support." type="error" showIcon />
      ) : (
        <Button type="primary" onClick={handleConfirmation} disabled={isLoading}>
          Confirm Account
        </Button>
      )}
    </div>
  );
};

export default Confirm;
