import React, { useEffect, useState } from 'react';
import { Form, Input, Button, List, Spin, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import MainLayout from '../../components/layout';
import { paymentMethodsApi } from '../../api/apiConfig';
import { PaymentMethod } from '../../api/generated';
import { handleError } from '../../utils';

const Profile = () => {
  const [user] = useState<{ email: string; name?: string }>({ email: '', name: '' });
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    paymentMethodsApi().getPaymentMethods().then((response) => {
      if (response.data) {
        setPaymentMethods(response.data);
      }
    }).catch((error) => {
      handleError(error);
    }).finally(() => {
      setLoading(false);
    });
  }, [refresh]); // useEffect depends on the refresh flag

  const onFinish = (values: { name: string; email: string }) => {
    console.log('Success:', values);
    // Additional code for updating profile can be uncommented here
  };

  const setDefaultPaymentMethod = (id: string) => {
    paymentMethodsApi().setDefaultPaymentMethod(id).then((response) => {
      if (response.data) {
        console.log(response.data.message);
        setRefresh(!refresh); // Toggle the refresh state to re-fetch data
      }
    }).catch((error) => {
      handleError(error);
    });
  };

  const deletePaymentMethod = (id: string) => {
    paymentMethodsApi().deletePaymentMethod(id).then(() => {
      setRefresh(!refresh); // Toggle the refresh state to re-fetch data
    }).catch((error) => {
      handleError(error);
    });
  };

  return (
    <MainLayout>
      <div className="container" style={{ maxWidth: '600px', margin: '40px auto' }}>
        <h1 className="text-center">User Profile</h1>
        <h2 className="text-center">Personal Information</h2>
        <Form
          name="profile"
          onFinish={onFinish}
          initialValues={user}
          autoComplete="off"
        >
          <Form.Item name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Profile</Button>
          </Form.Item>
        </Form>
        <Spin spinning={loading}>
          <h2 className="text-center">Payment Methods</h2>
          <List
            itemLayout="horizontal"
            dataSource={paymentMethods}
            renderItem={item => (
              <List.Item actions={[
                !item.default && <Button onClick={() => setDefaultPaymentMethod(item.id)}>Make Default</Button>,
                <Popconfirm
                  title="Are you sure you want to delete this payment method?"
                  onConfirm={() => deletePaymentMethod(item.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="default" danger>Delete</Button>
                </Popconfirm>
              ]}>
                <List.Item.Meta
                  title={item.name}
                  description={item.default ? 'Default Method' : 'Click to set as default'}
                />
              </List.Item>
            )}
          />
          <p>The default payment method will be used for automatic invoice processing.</p>
          <Link to="/profile/payments/update"><Button type="default">Add Payment Method</Button></Link>
        </Spin>
      </div>
    </MainLayout>
  );
};

export default Profile;
