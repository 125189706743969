import React, { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
// Assume usersApi has a login method
import { usersApi } from '../../api/apiConfig';
import { handleError } from '../../utils';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: { email: string; password: string }) => {
    setLoading(true);
    usersApi().loginUser(values).then(() => {
      navigate('/dashboard');
    }
    ).catch((error) => {
      handleError(error);
    }).finally(() => {
      setLoading(false);
    });

  };

  return (
    <div className="container" style={{ maxWidth: '400px', margin: '40px auto' }}>
      <h1 className="text-center">Login</h1>
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Login
          </Button>
        </Form.Item>
        <p className="text-center">
          No account? <a href="/register">Register</a>
        </p>
      </Form>
      {loading && <Spin />}
    </div>
  );
};

export default Login;
