import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button } from 'antd'
import { MySQLCredentials } from '../../api/generated'

type CopyCredentialsModalProps = {
  prefix: string;
  mySQLCredentials: MySQLCredentials | undefined;
  open: boolean;
  onClose: () => void;
};

const CopyCredentialsModal = ({ prefix, mySQLCredentials, open, onClose }: CopyCredentialsModalProps) => {
  // const [isModalVisible] = useState(open);
  const [credentialsPrefix, setCredentialsPrefix] = useState(prefix);
  const [editableCredentials, setEditableCredentials] = useState("");

  const formatCredentials = (prefix: string, creds: MySQLCredentials) => {
    // Format prefix to uppercase and replace spaces with underscores
    prefix = prefix.toUpperCase().replace(/\s+/g, '_');

    // Delete special characters from the prefix
    prefix = prefix.replace(/[^a-zA-Z0-9_]/g, "");

    // Add _ to the end of the prefix if it doesn't end with _ and is not empty
    if (prefix !== "" && prefix[prefix.length - 1] !== "_") {
      prefix += "_";
    }

    const credentials = `\
${prefix}MYSQL_HOST=${creds.hostname}
${prefix}MYSQL_PORT=${creds.port}
${prefix}MYSQL_USERNAME=${creds.username}
${prefix}MYSQL_PASSWORD=${creds.password}
${prefix}MYSQL_DATABASE=${creds.database}`;
    // Update only if necessary to avoid infinite loops or unwanted resets
    if (editableCredentials === "" || prefix !== credentialsPrefix) {
      setEditableCredentials(credentials);
    }
  };

  useEffect(() => {
    if (mySQLCredentials) formatCredentials(prefix, mySQLCredentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySQLCredentials, prefix]);

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrefix = e.target.value;
    setCredentialsPrefix(newPrefix);
    // Only reformat credentials if mySQLCredentials are available
    if (mySQLCredentials) formatCredentials(newPrefix, mySQLCredentials);
  };

  const handleCredentialsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableCredentials(e.target.value);
  };

  const copyCredentials = () => {
    navigator.clipboard.writeText(editableCredentials).then(() => {
      Modal.success({
        content: 'Credentials copied to clipboard!',
      });
    }, () => {
      Modal.error({
        title: 'Error',
        content: 'Failed to copy credentials.',
      });
    });
  };

  return (
    <Modal
      title="Copy MySQL Credentials"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="copy" type="primary" onClick={copyCredentials}>
          Copy to Clipboard
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Prefix">
          <Input value={credentialsPrefix} onChange={handlePrefixChange} />
        </Form.Item>
        <Form.Item label="Credentials">
          <Input.TextArea rows={6} value={editableCredentials} onChange={handleCredentialsChange} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CopyCredentialsModal
