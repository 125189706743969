import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Spin } from 'antd';
import MainLayout from '../../components/layout';
import SetupStatus from '../../components/stripe/SetupStatus';
import { StripeElementsOptions } from "@stripe/stripe-js";
import { stripePromise } from '../../config';

const PaymentStatus = () => {
  const [clientSecret, setClientSecret] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    setClientSecret(clientSecret || '');
    setLoading(false);

  }, []);


  const options: StripeElementsOptions = {
    clientSecret,
    locale: 'en',
  };

  return (
    <MainLayout>
      <div className="container" style={{ maxWidth: '600px', margin: '40px auto' }}>
        <h1 className="text-center">Update Payment Method</h1>
        <Spin spinning={loading}>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <SetupStatus clientSecret={clientSecret} />
            </Elements>
          ) : (
            <p>Loading payment information...</p>
          )}
        </Spin>
      </div>
    </MainLayout>
  );
};

export default PaymentStatus;
