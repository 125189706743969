import React, { ReactNode, useEffect } from "react";
import {
  MenuOutlined,
  UserOutlined,
  UnorderedListOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Dropdown, Button, MenuProps, Space } from "antd";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../auth";

const { Header, Sider, Content, Footer } = Layout;

interface MainLayoutProps {
  children: ReactNode;
  showSider?: boolean; // Optional prop to control the display of the sider
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  showSider = false,
}) => {
  const getMenuNumberFromPath = (path: string) => {
    if (path.includes("apps")) {
      return "1";
    } else if (path.includes("volumes")) {
      return "2";
    } else if (path.includes("mysqls")) {
      return "3";
    } else if (path.includes("redis")) {
      return "4";
    } else if (path.includes("globalenv")) {
      return "5";
    }
    return "";
  };

  // Get the menuNumber from the URL
  const location = useLocation();
  const path = location.pathname;
  const defNumber = getMenuNumberFromPath(path);

  // Get the projectId from the URL
  const { projectId } = useParams<{ projectId: string }>();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState(defNumber);

  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    const menuNumber = getMenuNumberFromPath(path);
    setActiveMenu(menuNumber);
  }, [path]);

  useEffect(() => {
    isAuthenticated().then((loggedIn) => {
      setLoggedIn(loggedIn);
    });
  }, [isAuthenticated]);

  const items: MenuProps["items"] = [
    {
      label: <Link to={`/profile`}>Account</Link>,
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: <Link to={`/invoices`}>Invoices</Link>,
      key: "2",
      icon: <UnorderedListOutlined />,
    },
    {
      label: "Logout",
      key: "3",
      icon: <LogoutOutlined />,
      danger: true,
      onClick: () => {
        logout();
        navigate("/login");
      },
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 50px",
          background: "#fff",
        }}
      >
        <Link to="/">HOSTIM.DEV</Link>
        {/* If logged in, then show menu, otherwise just a Login button */}
        {loggedIn ? (
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                <MenuOutlined />
              </Space>
            </Button>
          </Dropdown>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </Header>

      {showSider && (
        <Layout>
          <Sider width={200} className="site-layout-background">
            <Menu
              mode="inline"
              selectedKeys={[activeMenu]}
              style={{ height: "100%", borderRight: 0 }}
            >
              <Menu.Item key="1">
                <Link to={`/projects/${projectId}/apps`}>Apps</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/projects/${projectId}/volumes`}>Volumes</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/projects/${projectId}/mysqls`}>MySQLs</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/projects/${projectId}/redis`}>Redis</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to={`/projects/${projectId}/globalenv`}>
                  Global Environment
                </Link>
              </Menu.Item>
              {/* Add additional dynamic links as needed */}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
      {!showSider && (
        <Content style={{ padding: "0 50px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 380 }}
          >
            {children}
          </div>
        </Content>
      )}

      <Footer style={{ textAlign: "center" }}>
        Copyright &copy; 2022-{new Date().getFullYear()} ||{" "}
        <Link to="/pricing">Pricing</Link> ||{" "}
        <Link to="/privacy">Privacy Notice</Link>
      </Footer>
    </Layout>
  );
};

export default MainLayout;
