import React from "react";
import { Form, Select } from "antd";
import { App, AppPricing } from "../../../api/generated";

const { Option } = Select;

interface AppPricingProps {
  appPricings: AppPricing[];
  setApp: React.Dispatch<React.SetStateAction<App>>;
}

const AppPlan: React.FC<AppPricingProps> = ({ appPricings, setApp }) => {
  return (
    <div>
      <Form.Item
        label="Plan:"
        name="plan"
        rules={[{ required: true, message: "Please select a plan!" }]}
      >
        <Select
          onChange={(value) => setApp((prev) => ({ ...prev, plan: value }))}
        >
          {appPricings.map((plan) => (
            <Option key={plan.name} value={plan.name}>
              {`${plan.name} - €${plan.price} (${plan.ram}GB RAM, ${plan.cores} Cores, ${plan.type})`}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AppPlan;
