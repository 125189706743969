import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout";
import { App, AppPricing } from "../../api/generated";
import { appsApi, projectsApi, regionsApi } from "../../api/apiConfig";
import { useParams } from "react-router-dom";
import { handleError } from "../../utils";
import { Table, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { TableProps } from 'antd';
import { AppDeploymentSourceTypeEnum } from "../../api/generated";

const AppsPage: React.FC = () => {
  const [apps, setApps] = useState<App[]>([]);
  const [loading, setLoading] = useState(true);
  const projectId = useParams().projectId?.toString() || "";
  const [appPricings, setAppPricings] = useState<AppPricing[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    projectsApi()
      .getProject(projectId)
      .then((response) => {
        if (response.data) {
          const region = response.data.region || "";
          regionsApi()
            .getRegionAppPricing(region)
            .then((response) => {
              response.data.sort((a, b) => a.price - b.price);
              setAppPricings(response.data);
            })
            .catch((error) => handleError(error));
        }
      })
      .catch((error) => handleError(error));
  }, [projectId]);

  useEffect(() => {
    if (appPricings.length > 0) {
      setLoading(true);
      appsApi()
        .getApps(projectId)
        .then((response) => {
          if (!response.data) {
            return;
          }
          const updatedApps = computeCosts(response.data, appPricings);
          setApps(updatedApps);
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    }
  }, [projectId, appPricings]);

  const computeCosts = (apps: App[], pricings: AppPricing[]) => {
    return apps.map((app) => {
      const pricing = pricings.find((p) => p.name === app.plan);
      const cost = pricing ? pricing.price * app.replicas : 0;
      const str = pricing ? `€${cost.toFixed(2)}` : "N/A";
      return { ...app, costs: str };
    });
  };
  const columns: TableProps<App>['columns'] = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Plan", dataIndex: "plan", key: "plan" },
    { title: "Costs", dataIndex: "costs", key: "costs" },
    {
      title: "Deployment Type",
      dataIndex: "deploymentType",
      key: "deploymentType",
      render: (text, record) => record.deploymentSource ? record.deploymentSource.type : "Not specified",
    },
    {
      title: "Deployment Source",
      dataIndex: "deploymentSource",
      key: "deploymentSource",
      render: (text, record) => {
        switch (record.deploymentSource.type) {
          case AppDeploymentSourceTypeEnum.Docker:
            return record.deploymentSource.docker?.image
          case AppDeploymentSourceTypeEnum.Git:
            return record.deploymentSource.git?.url
          default:
            return "Not specified"
        }
      },
    },
  ];

  return (
    <MainLayout showSider={true}>
      <h1>Apps</h1>
      <Button type="primary" style={{ marginBottom: 16 }}>
        <Link to={`/projects/${projectId}/apps/new`}>Add New App</Link>
      </Button>
      {loading ? (
        <Spin />
      ) : (
        <Table
          dataSource={apps}
          columns={columns}
          rowKey="name"
          onRow={(record: App) => {
            return {
              onClick: () => {
                navigate(`/projects/${projectId}/apps/${record.name}`);
              },
            };
          }}
        />
      )}
    </MainLayout>
  );
};

export default AppsPage;
