import React from 'react';
import { Col, Row, Typography, Table } from 'antd';
import { RedisPricing } from '../../api/generated';

const { Title } = Typography;

interface RedisPricingComponentProps {
  redisPricings: RedisPricing[];
}

const RedisPricingComponent: React.FC<RedisPricingComponentProps> = ({ redisPricings }) => {
  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price (€)',
      dataIndex: 'price',
      key: 'price',
      render: (text: number) => `€ ${text}`,
    },
    {
      title: 'Volume (GB)',
      dataIndex: 'storage',
      key: 'storage',
    },
  ];

  const getDataSource = () => {
    return redisPricings
      .sort((a, b) => a.price - b.price)
      .map(plan => ({
        key: plan.name,
        name: plan.name,
        price: plan.price,
        storage: plan.storage,
      }));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={4}>Redis Plans</Title>
        <Table dataSource={getDataSource()} columns={columns} pagination={false} />
      </Col>
    </Row>
  );
};

export default RedisPricingComponent;
